import { getSortDirection } from '@root/pages/team/helpers/store'
import { endpoints } from '@root/transport/http/endpoints'
import { DashboardModel } from '@shared/models/dashboard'
import { RoleColumnFilter } from '@shared/models/role/column-filter'
import { RoleStatus } from '@shared/models/role/status'
import { ActiveDialog } from '../models/active-dialog'
import { SortColumn } from '../models/sort-column'
import { roleStoreApi } from './store'

export const fetchRoles = async () => {
  const { roles, columns } = await endpoints.roles.getAll.dispatch()

  roleStoreApi.setState({
    viewState: 'viewing',
    roles,
    columns
  })
}

export const resetRoles = () => roleStoreApi.setState({ viewState: 'fetching' })

export const setSearchQuery = (searchQuery: string) =>
  roleStoreApi.setState({ searchQuery })

export const sort = (sortColumn: SortColumn) => {
  const state = roleStoreApi.getState()
  roleStoreApi.setState({
    sortColumn,
    sortDirection: getSortDirection(
      state.sortColumn,
      sortColumn,
      state.sortDirection
    )
  })
}

export const openDialog = (
  activeDialog: ActiveDialog,
  selectedRoleId?: number
) => roleStoreApi.setState({ activeDialog, selectedRoleId })

export const closeDialog = () => roleStoreApi.setState({ activeDialog: 'none' })

export const updateRoleStatus = async (status: RoleStatus) => {
  const { roles, selectedRoleId } = roleStoreApi.getState()
  roleStoreApi.setState({
    roles: roles.map((role) =>
      role.id === selectedRoleId ? { ...role, status } : role
    )
  })
}

export const saveRole = async (
  name: string,
  columnFilters: Array<RoleColumnFilter>,
  reportSchedulingCapabilities: boolean,
  onlyShowDashboard: boolean,
  dashboards: Array<DashboardModel>,
  admin: boolean
) => {
  const { selectedRoleId } = roleStoreApi.getState()

  const role = await endpoints.roles.save.dispatch({
    id: selectedRoleId,
    name,
    columnFilters,
    reportSchedulingCapabilities,
    onlyShowDashboard,
    dashboards,
    admin
  })

  const { roles } = roleStoreApi.getState()

  roleStoreApi.setState({
    roles: selectedRoleId
      ? roles.map((_role) => (_role.id === role.id ? role : _role))
      : [...roles, role],
    activeDialog: 'none'
  })
}

export const deleteRole = async () => {
  const { selectedRoleId } = roleStoreApi.getState()

  if (selectedRoleId) {
    await endpoints.roles.remove.dispatch(selectedRoleId)
    roleStoreApi.setState({
      roles: roleStoreApi
        .getState()
        .roles.filter((role) => role.id !== selectedRoleId),
      activeDialog: 'none'
    })
  }
}
