import { DeleteDialog } from '@root/components/dialog/delete-dialog'
import { closeDialog, removeReport } from '@root/pages/dashboard/store/actions'
import { useDashboardStore } from '@root/pages/dashboard/store/store'
import React from 'react'

export const DeleteReportDialog: React.FC<{}> = () => {
  const [activeDialog] = useDashboardStore((state) => [state.activeDialog])

  return (
    <DeleteDialog
      open={activeDialog === 'delete-report'}
      type="report"
      close={closeDialog}
      remove={removeReport}
    />
  )
}
