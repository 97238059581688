import { DataChartModel } from '@shared/models/charts/any-chart'
import { ChartTypeModel } from '@shared/models/charts/chart-type'
import { useChartBuilderStore } from './store'

export const useAvailableChartTypes = (): Array<ChartTypeModel> => {
  const [types, queries, method, queryId] = useChartBuilderStore((state) => [
    state.types,
    state.queries,
    state.method,
    state.queryId
  ])
  const query = queries.find((_query) => _query.id === queryId)!

  return types.filter(
    (type) => method === 'custom' || query.availableTypes.includes(type.id)
  )
}

export const useChart = (): DataChartModel => {
  const [
    id,
    name,
    method,
    variant,
    queryId,
    advancedQueryId,
    typeId,
    customQuery,
    fromDateType,
    fromDateValue,
    toDate,
    top,
    filters,
    data,
    types
  ] = useChartBuilderStore((state) => [
    state.id,
    state.name,
    state.method,
    state.variant,
    state.queryId,
    state.advancedQueryId,
    state.typeId,
    state.customQuery,
    state.fromDateType,
    state.fromDateValue,
    state.toDate,
    state.top,
    state.filters,
    state.data,
    state.types
  ])

  const advanced = method === 'advanced'

  return {
    id: id || 0,
    name,
    typeId: advanced
      ? types.find((type) => type.variant === 'single-value')?.id || 0
      : typeId,
    method,
    variant: advanced ? 'single-value' : variant,
    queryId,
    advancedQueryId,
    customQuery,
    fromDateType,
    fromDateValue,
    toDate,
    top,
    filters,
    data
  }
}
