import { Box, Grid } from '@material-ui/core'
import { Button } from '@root/components/button'
import { Dialog } from '@root/components/dialog/dialog'
import React, { useEffect } from 'react'
import { closeDialog } from '../../store/actions'
import { Field } from './components/field'
import { Recipients } from './components/recipients'
import { Schedules } from './components/schedules'
import { init, reset, save } from './store/actions'
import { useCanSave, useErrors, useOpen } from './store/selectors'

export const ScheduleReportDialog: React.FC = () => {
  const errors = useErrors()
  const canSave = useCanSave()
  const open = useOpen()

  useEffect(() => {
    open && init()

    return () => {
      !open && reset()
    }
  }, [open])

  return (
    <Dialog
      open={open}
      large
      title=""
      content={
        <Box py={2}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Field field="name" errors={errors} required autoFocus />
            </Grid>
            <Grid item xs={12}>
              <Schedules />
            </Grid>
            <Grid item xs={12}>
              <Recipients />
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <>
          <Button primary disabled={!canSave} onClick={save}>
            Save changes
          </Button>
        </>
      }
      onClose={closeDialog}
    />
  )
}
