import { Box, Grid, MenuItem } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { FormField } from '@root/components/form-field'
import { OutlineButton } from '@root/components/outline-button'
import { SwitchToggle } from '@root/components/switch-toggle'
import React from 'react'
import {
  addSchedule,
  removeSchedule,
  toggleScheduleActive,
  updateSchedule
} from '../store/actions'
import { useScheduleReportDialogStore } from '../store/store'

export const Schedules: React.FC = () => {
  const [saving, schedules] = useScheduleReportDialogStore((state) => [
    state.saving,
    state.schedules
  ])

  return (
    <Grid container spacing={2}>
      {schedules.map((schedule, index) => (
        <Grid key={index} item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs>
              <FormField
                select
                label="Repeat"
                value={schedule.interval}
                required={false}
                disabled={saving}
                minWidth={269}
                setField={(value) => updateSchedule(index, 'interval', value)}
                remove={() => removeSchedule(index)}
              >
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="yearly">Yealy</MenuItem>
              </FormField>
            </Grid>
            <Grid item xs>
              <FormField
                select
                label="At"
                value={schedule.day}
                required={false}
                disabled={saving}
                setField={(value) => updateSchedule(index, 'day', value)}
              >
                <MenuItem value="monday">Monday</MenuItem>
                <MenuItem value="tuesday">Tuesday</MenuItem>
                <MenuItem value="wednesday">Wednesday</MenuItem>
                <MenuItem value="thursday">Thursday</MenuItem>
                <MenuItem value="friday">Friday</MenuItem>
                <MenuItem value="saturday">Saturday</MenuItem>
                <MenuItem value="sunday">Sunday</MenuItem>
              </FormField>
            </Grid>
            <Grid item xs>
              <FormField
                select
                label=""
                value={schedule.time}
                required={false}
                disabled={saving}
                setField={(value) => updateSchedule(index, 'time', value)}
              >
                {Array.from(Array(24)).map((_, time) => (
                  <MenuItem key={time} value={`${time}:00`}>
                    {`${time}:00`}
                  </MenuItem>
                ))}
              </FormField>
            </Grid>
            <Grid item xs>
              <FormField
                select
                label="Time zone"
                value={schedule.timeZone}
                required={false}
                disabled={saving}
                setField={(value) => updateSchedule(index, 'timeZone', value)}
              >
                <MenuItem value="utc">UTC</MenuItem>
                <MenuItem value="utc+1">UTC + 1</MenuItem>
              </FormField>
            </Grid>
            <Grid item xs="auto">
              <Box display="flex" flexDirection="column" height="100%">
                <Box flexGrow={0.5}>
                  <Box component="span" fontSize={13} fontWeight={500}>
                    Active
                  </Box>
                </Box>
                <SwitchToggle
                  active={schedule.active}
                  toggle={() => toggleScheduleActive(index)}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <OutlineButton primary icon={<Add />} iconRight onClick={addSchedule}>
          Add schedule
        </OutlineButton>
      </Grid>
    </Grid>
  )
}
