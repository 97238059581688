import { Paper } from '@root/components/paper'
import { DataChartModel } from '@shared/models/charts/any-chart'
import { DashboardModel } from '@shared/models/dashboard'
import React, { useEffect } from 'react'
import { Loader } from '../loader'
import { Builder } from './components/builder'
import { NameDialog } from './components/name-dialog'
import { Navigation } from './components/navigation'
import { Overview } from './components/overview'
import { ChartFinalized } from './steps/chart-finalized'
import { Filters } from './steps/filters'
import { SelectChart } from './steps/select-chart'
import { WhatToSee } from './steps/what-to-see/what-to-see'
import {
  fetchChartBuilderData,
  reset,
  setChart,
  setLoading,
  traverseStep
} from './store/actions'
import { useChartBuilderStore } from './store/store'

interface Props {
  type: 'dashboard' | 'library'
  chart: DataChartModel | undefined
  dashboards: Array<DashboardModel>
  initialDashboardId?: number
  goBack: () => void
  save: (chart: DataChartModel, dashboardId?: number) => void
  remove?: () => void
}

export const ChartBuilder: React.FC<Props> = ({
  type,
  chart,
  dashboards,
  initialDashboardId,
  goBack,
  save,
  remove
}) => {
  const [
    viewState,
    name,
    method,
    currentStep
  ] = useChartBuilderStore((state) => [
    state.viewState,
    state.name,
    state.method,
    state.currentStep
  ])

  useEffect(() => {
    fetchChartBuilderData()

    return reset
  }, [])

  useEffect(() => {
    setChart(chart)
  }, [chart])

  const dispatchSave = (
    _chart: DataChartModel,
    dashboardId?: number | undefined
  ) => {
    setLoading()
    save(_chart, dashboardId)
  }

  return (
    <>
      {viewState === 'fetching' && <Loader />}
      {viewState !== 'fetching' && (
        <>
          <Overview name={name} goBack={goBack} />
          <Paper>
            <Navigation
              currentStep={currentStep}
              mayManage={Boolean(chart?.id)}
              save={(_chart) => dispatchSave(_chart, initialDashboardId)}
              remove={remove}
            />
            <Builder
              canTraverse={
                currentStep !== 'chart-finalized' &&
                currentStep !== 'name-chart'
              }
              canToggleMethod={
                currentStep === 'what-to-see' && method === 'regular'
              }
              traverseStep={() => traverseStep(dispatchSave, Boolean(chart))}
            >
              {currentStep === 'what-to-see' && <WhatToSee />}
              {currentStep === 'filters' && <Filters />}
              {currentStep === 'select-chart' && <SelectChart />}
              {(currentStep === 'chart-finalized' ||
                currentStep === 'name-chart') && (
                <ChartFinalized
                  type={type}
                  dashboards={dashboards}
                  initialDashboardId={initialDashboardId}
                  save={dispatchSave}
                />
              )}
              <NameDialog save={dispatchSave} />
            </Builder>
          </Paper>
        </>
      )}
    </>
  )
}
