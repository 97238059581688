import { Box, Grid, TextField } from '@material-ui/core'
import { Button } from '@root/components/button'
import { Dialog } from '@root/components/dialog/dialog'
import { OutlineButton } from '@root/components/outline-button'
import React, { useEffect } from 'react'
import { closeDialog, saveDashboard } from '../../store/actions'
import { useDashboardStore } from '../../store/store'
import { Colors } from './components/colors'
import { Icons } from './components/icons'
import { gotoLayout, reset, setDescription, setName } from './store/actions'
import { useSaveDialogStore } from './store/store'

export const SaveDialog: React.FC<{}> = () => {
  const [activeDialog, selectedDashboardId] = useDashboardStore((state) => [
    state.activeDialog,
    state.selectedDashboardId
  ])
  const [
    view,
    id,
    name,
    description,
    type,
    color
  ] = useSaveDialogStore((state) => [
    state.view,
    state.id,
    state.name,
    state.description,
    state.type,
    state.color
  ])

  useEffect(() => {
    if (activeDialog === 'add') {
      reset(selectedDashboardId)
    }
  }, [activeDialog, selectedDashboardId])

  return (
    <Dialog
      open={activeDialog === 'add'}
      title="Add dashboard"
      content={
        <>
          {view === 'naming' && (
            <Box py={2}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    id="description"
                    label="Description"
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          {view === 'layout' && (
            <>
              <Icons />
              <Colors />
            </>
          )}
        </>
      }
      actions={
        <>
          <OutlineButton secondary onClick={closeDialog}>
            Cancel
          </OutlineButton>
          {view === 'naming' ? (
            <Button primary onClick={gotoLayout}>
              Next
            </Button>
          ) : (
            <Button
              primary
              onClick={() => saveDashboard(id, name, description, type, color)}
            >
              Save
            </Button>
          )}
        </>
      }
      onClose={closeDialog}
    />
  )
}
