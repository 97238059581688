import { IconButton } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import { DataCell } from '@root/components/data-table/data-cell'
import { DataRow } from '@root/components/data-table/data-row'
import { EmptyColumn } from '@root/components/data-table/empty-column'
import { RoleDetailModel } from '@shared/models/role/detail'
import React from 'react'
import { Status } from '../../../components/status'
import { TeamTable } from '../../../components/team-table'
import { openDialog } from '../../store/actions'
import { useRoles } from '../../store/selectors'
import { useRoleStore } from '../../store/store'
import { RoleColumn } from '../role-column'
import { RoleMenu } from './components/role-menu'

export const RoleTable = () => {
  const roles = useRoles()
  const viewState = useRoleStore((state) => state.viewState)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedRoleId, setSelectedRoleId] = React.useState<number>(0)

  const handleOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    role: RoleDetailModel
  ) => {
    setAnchorEl(event.currentTarget)
    setSelectedRoleId(role.id)
  }

  return (
    <>
      <TeamTable
        loading={viewState === 'fetching'}
        addButtonLabel="Add role"
        columns={
          <>
            <RoleColumn label="Role" column="name" width="55%" />
            <RoleColumn label="Admin" column="admin" width="20%" />
            <RoleColumn label="Status" column="status" width="20%" />
            <EmptyColumn width="5%" />
          </>
        }
        dataRows={
          <>
            {roles.map((role, index) => (
              <DataRow key={index}>
                <DataCell>
                  <span
                    onClick={() => openDialog('edit', role.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {role.name}
                  </span>
                </DataCell>
                <DataCell>{role.admin ? 'Yes' : 'No'}</DataCell>
                <DataCell>
                  <Status status={role.status} />
                </DataCell>
                <DataCell alignRight>
                  <IconButton onClick={(e) => handleOpen(e, role)}>
                    <MoreHoriz />
                  </IconButton>
                </DataCell>
              </DataRow>
            ))}
          </>
        }
        addButtonClick={() => openDialog('edit')}
      />
      <RoleMenu
        anchorEl={anchorEl}
        selectedRoleId={selectedRoleId}
        close={() => setAnchorEl(null)}
      />
    </>
  )
}
