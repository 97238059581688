import { Tabs } from '@root/components/tabs'
import React from 'react'
import { openDashboard } from '../store/actions'
import { useDashboardStore } from '../store/store'

export const DashboardTabs: React.FC<{}> = () => {
  const [dashboards, selectedDashboardId] = useDashboardStore((state) => [
    state.dashboards,
    state.selectedDashboardId
  ])

  return (
    <Tabs
      tabs={dashboards.map((dashboard) => ({
        label: dashboard.label,
        value: dashboard.id.toString()
      }))}
      currentTab={selectedDashboardId?.toString() || ''}
      size="small"
      scrollable
      setTab={(value) => openDashboard(Number(value))}
    />
  )
}
