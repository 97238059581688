import { Grid } from '@material-ui/core'
import { Loader } from '@root/components/loader'
import React, { useEffect } from 'react'
import { Customer } from './components/customer/customer'
import { Details } from './components/details'
import { OperationsHistory } from './components/operations-history'
import { Overview } from './components/overview'
import { Summary } from './components/summary'
import { fetchTransaction, resetTransaction } from './store/actions'
import { useCustomerDetailFields, useDetailFields } from './store/selectors'
import { useTransactionDetailStore } from './store/store'

export const TransactionDetail: React.FC = () => {
  const viewState = useTransactionDetailStore((state) => state.viewState)
  const detailFields = useDetailFields()
  const customerDetailFields = useCustomerDetailFields()

  useEffect(() => {
    fetchTransaction()
    return resetTransaction
  }, [])

  return (
    <>
      <Overview />
      {viewState === 'fetching' && <Loader />}
      {viewState !== 'fetching' && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Summary />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Details title="DETAILS" fields={detailFields} />
              </Grid>
              <Grid item xs={5}>
                <Details title="CARD DETAILS" fields={customerDetailFields} />
              </Grid>
              <Grid item xs={3}>
                <Customer />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <OperationsHistory />
          </Grid>
        </Grid>
      )}
    </>
  )
}
