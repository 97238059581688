import { DeleteDialog } from '@root/components/dialog/delete-dialog'
import React from 'react'
import { closeDialog, removeChart } from '../store/actions'
import { useLibraryStore } from '../store/store'

export const DeleteChartDialog: React.FC<{}> = () => {
  const [activeDialog] = useLibraryStore((state) => [state.activeDialog])

  return (
    <DeleteDialog
      open={activeDialog === 'delete-chart'}
      type="chart"
      close={closeDialog}
      remove={removeChart}
    />
  )
}
