import { ListItemIcon, MenuItem, Typography } from '@material-ui/core'
import {
  Delete,
  Edit as EditIcon,
  MoreVert,
  Reorder as ReorderIcon,
  WatchLater
} from '@material-ui/icons'
import { Dropdown } from '@root/components/dropdown/dropdown'
import { OutlineButton } from '@root/components/outline-button'
import { PageHeader } from '@root/components/page-header'
import React from 'react'
import { openDialog, toggleEditing, toggleEditType } from '../../store/actions'
import { useDashboardStore } from '../../store/store'
import { DashboardTabs } from '../dashboard-tabs'
import { DeleteDashboardDialog } from './components/delete-dialog'
import { DeleteReportDialog } from './components/delete-report-dialog'
import { RenameDialog } from './components/rename-dialog'
import { Reorder } from './components/reorder/reorder'
import { Reports } from './components/reports'

export const Edit: React.FC<{}> = () => {
  const [viewState, dashboards] = useDashboardStore((state) => [
    state.viewState,
    state.dashboards
  ])

  return (
    <>
      <PageHeader
        title={
          viewState === 'edit-order'
            ? 'Reorder your charts'
            : 'Scheduled reports'
        }
        tabs={<DashboardTabs />}
        action={
          <Dropdown
            button={
              <OutlineButton secondary icon={<MoreVert />}>
                Edit dashboard
              </OutlineButton>
            }
          >
            <MenuItem onClick={toggleEditType}>
              <ListItemIcon>
                {viewState === 'edit-order' && <WatchLater />}
                {viewState === 'edit-reports' && <ReorderIcon />}
              </ListItemIcon>
              <Typography variant="inherit">
                {viewState === 'edit-order' && 'Scheduled reports'}
                {viewState === 'edit-reports' && 'Reorder'}
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => openDialog('rename-dashboard')}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <Typography variant="inherit">Rename</Typography>
            </MenuItem>
            {dashboards.length > 1 && (
              <MenuItem onClick={() => openDialog('delete-dashboard')}>
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <Typography variant="inherit">Delete</Typography>
              </MenuItem>
            )}
          </Dropdown>
        }
        goBack={toggleEditing}
      />
      {viewState === 'edit-order' && <Reorder />}
      {viewState === 'edit-reports' && <Reports />}
      <DeleteDashboardDialog />
      <DeleteReportDialog />
      <RenameDialog />
    </>
  )
}
