import TextField from '@material-ui/core/TextField'
import { setEmail } from '@root/store/auth/actions'
import { useAuthStore } from '@root/store/auth/store'
import React from 'react'

export const EmailField: React.FC = () => {
  const [authState, email] = useAuthStore((state) => [
    state.authState,
    state.email
  ])

  return (
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      label="Email"
      autoComplete="email"
      autoFocus
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      disabled={authState === 'authenticating'}
    />
  )
}
