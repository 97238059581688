import { Box, Grid, Typography } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import React from 'react'
import { Legend, LegendProps } from 'recharts'
import { legendClassName } from '../constants/legend-class-name'
import { getLabelColor } from './label-color'

const content = ({ payload }: LegendProps) => (
  <Box mb={4} className={legendClassName}>
    <Grid container spacing={2}>
      {payload?.map((entry, index) => (
        <Grid key={index} item>
          <Box display="flex" alignItems="center">
            <Box
              mr={1}
              width={19}
              height={19}
              border={`3px solid ${getLabelColor(entry.color || '')}`}
              borderRadius="100%"
            />
            <Typography
              variant="subtitle2"
              style={{ color: theme.palette.dark('lighter') }}
            >
              {entry.value}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Box>
)

export const renderLegend = () => (
  <Legend
    iconType="circle"
    verticalAlign="top"
    wrapperStyle={{ top: 0 }}
    content={content}
  />
)
