import React from 'react'

interface Props {
  country: string
  small?: boolean
}

export const Flag: React.FC<Props> = ({ country, small }) => (
  <img
    alt="country"
    width={small ? 24 : 32}
    height={small ? 24 : 32}
    src={`/flags/${country.slice(0, 1).toUpperCase()}${country
      .slice(1)
      .toLowerCase()}.png`}
  />
)
