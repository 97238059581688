import { theme } from '@root/constants/theme'
import React from 'react'

// TODO: Use moment
export const renderQuarterTick = (tickProps: any) => {
  const { x, y, payload } = tickProps
  const { value, offset } = payload
  const date = new Date(value)
  const month = date.getMonth()
  const year = date.getFullYear()
  const day = date.getDate()

  if (tickProps.index === 0 || (month === 0 && day === 0)) {
    return (
      <text
        x={x + offset}
        y={y - 8}
        textAnchor="middle"
        fontSize={13}
        fill={theme.palette.dark('lighter')}
      >
        {year}
      </text>
    )
  }
}
