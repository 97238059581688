import { Grid } from '@material-ui/core'
import { ChartSelection } from '@root/components/chart-builder/components/chart-selection'
import React from 'react'
import { setAdvancedQuery } from '../../../store/actions'
import { useChartBuilderStore } from '../../../store/store'

export const Advanced: React.FC<{}> = () => {
  const [advancedQueries, advancedQueryId] = useChartBuilderStore((state) => [
    state.advancedQueries,
    state.advancedQueryId
  ])

  return (
    <Grid container spacing={4}>
      {advancedQueries.map((advancedQuery, index) => (
        <Grid key={index} item xs={3}>
          <ChartSelection
            variant="query"
            image={advancedQuery.image}
            title={advancedQuery.name}
            selected={advancedQueryId === advancedQuery.id}
            click={() => setAdvancedQuery(advancedQuery.id)}
          />
        </Grid>
      ))}
    </Grid>
  )
}
