import { DeleteDialog as Dialog } from '@root/components/dialog/delete-dialog'
import React from 'react'
import { closeDialog, removeDashboard } from '../store/actions'
import { useDashboardStore } from '../store/store'

export const DeleteDialog: React.FC<{}> = () => {
  const [activeDialog] = useDashboardStore((state) => [state.activeDialog])

  return (
    <Dialog
      open={activeDialog === 'delete'}
      type="dashboard"
      close={closeDialog}
      remove={removeDashboard}
    />
  )
}
