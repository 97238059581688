import { DeleteDialog } from '@root/components/dialog/delete-dialog'
import React from 'react'
import { closeDialog, deleteRole } from '../store/actions'
import { useRoleStore } from '../store/store'

export const DeleteRoleDialog: React.FC = () => {
  const [activeDialog] = useRoleStore((state) => [state.activeDialog])

  return (
    <DeleteDialog
      open={activeDialog === 'delete'}
      type="role"
      close={closeDialog}
      remove={deleteRole}
    />
  )
}
