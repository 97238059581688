import { TextField } from '@material-ui/core'
import { Button } from '@root/components/button'
import { Dialog } from '@root/components/dialog/dialog'
import { OutlineButton } from '@root/components/outline-button'
import React, { useEffect, useState } from 'react'

interface Props {
  open: boolean
  add: (name: string) => void
  close: () => void
}

export const DraftDialog: React.FC<Props> = ({ open, add, close }) => {
  const [name, setName] = useState('')

  useEffect(() => {
    if (open) {
      setName('')
    }
  }, [open])

  return (
    <Dialog
      open={open}
      title="Save draft as"
      content={
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      }
      actions={
        <>
          <OutlineButton secondary onClick={close}>
            Cancel
          </OutlineButton>
          <Button primary onClick={() => add(name)}>
            Save
          </Button>
        </>
      }
      onClose={close}
    />
  )
}
