import { DetailField } from '../models/detail-field'
import { useTransactionDetailStore } from './store'

export const useDetailFields = (): Array<DetailField> => {
  const transaction = useTransactionDetailStore((state) => state.transaction)

  return [
    {
      type: 'text',
      label: 'Invoice ID',
      value: transaction?.details.invoiceId
    },
    { type: 'text', label: 'Available', value: transaction?.details.available },
    {
      type: 'text',
      label: 'Created on',
      value: transaction?.details.createdOn
    },
    { type: 'text', label: 'Fees', value: transaction?.details.fees },
    { type: 'text', label: 'Shipping', value: transaction?.details.shipping },
    { type: 'text', label: 'Taxes', value: transaction?.details.taxes },
    {
      type: 'status',
      label: 'AVS check',
      value: transaction?.details.avsCheck
    },
    { type: 'text', label: 'Currency', value: transaction?.details.currency },
    {
      type: 'text',
      label: 'Payment type',
      value: transaction?.details.paymentType
    },
    { type: 'text', label: 'Eci', value: transaction?.details.eci },
    { type: 'text', label: 'Mcc', value: transaction?.details.mcc },
    {
      type: 'text',
      label: 'Merchant account',
      value: transaction?.details.merchantAccount
    },
    { type: 'text', label: 'Acquier', value: transaction?.details.acquirer },
    { type: 'text', label: 'Is on us', value: transaction?.details.isOnUs },
    {
      type: 'text',
      label: 'External gateway used',
      value: transaction?.details.externalGatewayUsed
    }
  ]
}

export const useCustomerDetailFields = (): Array<DetailField> => {
  const transaction = useTransactionDetailStore((state) => state.transaction)

  return [
    {
      type: 'text',
      label: 'Name on card',
      value: transaction?.cardDetails.nameOnCard
    },
    {
      type: 'text',
      label: 'Last 4 digits',
      value: transaction?.cardDetails.lastDigits
    },
    { type: 'text', label: 'IIN', value: transaction?.cardDetails.iin },
    {
      type: 'text',
      label: 'Bank name',
      value: transaction?.cardDetails.bankName
    },
    { type: 'text', label: 'Type', value: transaction?.cardDetails.type },
    {
      type: 'text',
      label: 'Category',
      value: transaction?.cardDetails.category
    },
    {
      type: 'text',
      label: 'Expiration date',
      value: transaction?.cardDetails.expirationDate
    },
    { type: 'scheme', label: 'Scheme', value: transaction?.cardDetails.scheme },
    {
      type: 'text',
      label: 'Co-Scheme',
      value: transaction?.cardDetails.coScheme
    },
    { type: 'text', label: 'Brand', value: transaction?.cardDetails.brand },
    { type: 'flag', label: 'Country', value: transaction?.cardDetails.country },
    { type: 'text', label: 'Address', value: transaction?.cardDetails.address },
    {
      type: 'status',
      label: 'AVS-check',
      value: transaction?.cardDetails.avsCheck
    },
    {
      type: 'status',
      label: 'CVC-check',
      value: transaction?.cardDetails.cvcCheck
    },
    {
      type: 'status',
      label: '3-D Secure',
      value: transaction?.cardDetails.threeDSecure
    }
  ]
}
