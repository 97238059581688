import { ChartBuilder } from '@root/components/chart-builder/chart-builder'
import { PageHeader } from '@root/components/page-header'
import { RealtimeEditor } from '@root/components/realtime-editor/realtime-editor'
import { Tabs } from '@root/components/tabs'
import { setTab, useTabStore } from '@root/store/tab'
import { navigate, usePath, useRoutes } from 'hookrouter'
import React, { useEffect } from 'react'
import { Charts } from './pages/charts/charts'
import { closeChartBuilder, saveChart } from './pages/charts/store/actions'
import { useSelectedChart } from './pages/charts/store/selectors'
import { useLibraryStore } from './pages/charts/store/store'
import { Dashboards } from './pages/dashboards/dashboards'

const routes = {
  charts: () => <Charts />,
  dashboards: () => <Dashboards />
}

export const Library = () => {
  const tab = useTabStore((state) => state.tab)
  const path = usePath()
  const routeResult = useRoutes(routes)
  const viewState = useLibraryStore((state) => state.viewState)
  const chart = useSelectedChart()

  useEffect(() => {
    setTab(path)
  }, [path])

  if (viewState === 'chart-builder') {
    return (
      <ChartBuilder
        type="library"
        dashboards={[]}
        chart={chart}
        goBack={closeChartBuilder}
        save={(_chart) => saveChart(_chart)}
      />
    )
  } else if (viewState === 'realtime-editor') {
    return (
      <RealtimeEditor
        type="library"
        dashboards={[]}
        chart={chart}
        goBack={closeChartBuilder}
        save={(_chart) => saveChart(_chart, true)}
      />
    )
  }

  return (
    <>
      <PageHeader
        title="Library"
        tabs={
          <Tabs
            tabs={[
              { label: 'Charts', value: '/library/charts' },
              { label: 'Dashboards', value: '/library/dashboards' }
            ]}
            currentTab={tab}
            size="small"
            setTab={(value) => navigate(value)}
          />
        }
      />
      <div>{routeResult || <div>no subview found</div>}</div>
    </>
  )
}
