import { sortByColumn } from '@root/helpers/sort'
import { FilterComparator } from '@shared/models/charts/filter-comparator'
import { SortColumn } from '@shared/models/transactions/sort-column'
import unique from 'array-unique'
import { columns } from '../constants/columns'
import { TransactionViewModel } from '../models/transaction-view'
import { useTransactionStore } from './store'

const getPages = <T>(itemsPerPage: number, items: Array<T>): number =>
  Math.ceil(items.length / itemsPerPage)

const getColumnLabel = (column: SortColumn): string =>
  columns.find(({ key }) => key === column)?.label || ''

const getColumns = (visibleColumns: Array<SortColumn>) =>
  columns.filter((column) =>
    visibleColumns.find((_column) => _column === column.key)
  )

export const useUnusedFilterColumns = () => {
  const [transactions, filters] = useTransactionStore((state) => [
    state.transactions,
    state.filters
  ])

  const filterColumns: Array<SortColumn> = transactions.length
    ? (Object.keys(transactions[0]) as Array<SortColumn>)
    : []

  return filterColumns
    .filter((column) => !filters.find((filter) => filter.column === column))
    .map((column) => ({
      column,
      label: getColumnLabel(column)
    }))
}

export const useColumns = () => {
  const visibleColumns = useTransactionStore((state) => state.visibleColumns)

  return columns.map((column) => ({
    ...column,
    visible: Boolean(visibleColumns.find((_column) => _column === column.key))
  }))
}

export const useVisibleColumns = () => {
  const visibleColumns = useTransactionStore((state) => state.visibleColumns)

  return getColumns(visibleColumns)
}

const filterColumnValue = (
  filterValue: string,
  comparator: FilterComparator,
  columnValue: string | number
): boolean => {
  switch (comparator) {
    case 'equals':
      return filterValue
        .toLowerCase()
        .includes(columnValue.toString().toLowerCase())
    case 'greater-than':
      return (
        Number(columnValue.toString().replace(/[^0-9.]+/g, '')) >
        Number(filterValue.replace(/[^0-9.]+/g, ''))
      )
    case 'less-than':
      return (
        Number(columnValue.toString().replace(/[^0-9.]+/g, '')) <
        Number(filterValue.replace(/[^0-9.]+/g, ''))
      )
  }

  return false
}

export const useTransactions = (): Array<TransactionViewModel> => {
  const [
    transactions,
    filters,
    selectedFilterColumn,
    visibleColumns,
    selectedTransactions,
    sortColumn,
    sortDirection,
    itemsPerPage,
    page
  ] = useTransactionStore((state) => [
    state.transactions,
    state.filters,
    state.selectedFilterColumn,
    state.visibleColumns,
    state.selectedTransactions,
    state.sortColumn,
    state.sortDirection,
    state.itemsPerPage,
    state.page
  ])

  const activeFilters = filters.filter(
    (filter) => filter.column !== selectedFilterColumn
  )

  return transactions
    .filter((transaction) =>
      activeFilters.every(
        (filter) =>
          filter.value === '' ||
          filterColumnValue(
            filter.value,
            filter.comparator,
            transaction[filter.column]
          )
      )
    )
    .slice(itemsPerPage * (page - 1), itemsPerPage * page)
    .map((transaction) => ({
      ...transaction,
      selected: Boolean(
        selectedTransactions.find(
          (transactionNumber) =>
            transactionNumber === transaction.transaction_id
        )
      ),
      columns: getColumns(visibleColumns).map((column) => column.key)
    }))
    .sort((a, b) => sortByColumn(sortColumn, sortDirection, a, b))
}

export const useFilters = () => {
  const [
    filters,
    transactions,
    selectedFilterColumn
  ] = useTransactionStore((state) => [
    state.filters,
    state.transactions,
    state.selectedFilterColumn
  ])

  return filters.map((filter) => ({
    ...filter,
    label: getColumnLabel(filter.column),
    selected: selectedFilterColumn === filter.column,
    values: unique(
      transactions.map((transaction) => transaction[filter.column])
    )
  }))
}

export const usePages = () => {
  const [transactions, itemsPerPage] = useTransactionStore((state) => [
    state.transactions,
    state.itemsPerPage
  ])

  return Array(getPages(itemsPerPage, transactions))
    .fill(0)
    .map((zero, index) => index + 1)
}

export const useNextPage = () => {
  const [transactions, itemsPerPage, page] = useTransactionStore((state) => [
    state.transactions,
    state.itemsPerPage,
    state.page
  ])

  return page < getPages(itemsPerPage, transactions)
}

export const usePreviousPage = () => {
  const page = useTransactionStore((state) => state.page)

  return page > 1
}

export const useSelectedAll = () => {
  const transactions = useTransactions()

  return transactions.every((transaction) => transaction.selected)
}
