import { Grid, Typography, Box } from '@material-ui/core'
import React from 'react'
import { useImplementationGuideStore } from '../store/store'
import { DataTable } from './data-table'

export const Synchronize: React.FC<{}> = () => {
  const [
    synchronizeTables,
    bigQueryTables
  ] = useImplementationGuideStore((state) => [
    state.synchronizeTables,
    state.bigQueryTables
  ])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">
          Synchronize the table structure on the BigQuery tables
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {bigQueryTables.map((table, index) => (
            <DataTable key={index} table={table} />
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box mt={2}>
          <Typography variant="h4">
            Synchronize the IDs between the BigQuery data and our tables
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {synchronizeTables.map((table, index) => (
            <DataTable key={index} table={table} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
