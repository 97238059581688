import { SortDirection } from '../models/sort-direction'

export const getSortDirection = (
  sortColumn: string,
  newSortColumn: string,
  sortDirection: SortDirection
) => {
  if (sortColumn === newSortColumn) {
    return sortDirection === 'asc' ? 'desc' : 'asc'
  }

  return 'asc'
}

export const sortByColumn = <
  TColumn extends string,
  TModel extends { [key: string]: any }
>(
  sortColumn: TColumn,
  sortDirection: SortDirection,
  a: TModel,
  b: TModel
): number => {
  if (a[sortColumn] < b[sortColumn]) {
    return sortDirection === 'asc' ? -1 : 1
  }
  if (a[sortColumn] > b[sortColumn]) {
    return sortDirection === 'asc' ? 1 : -1
  }
  return 0
}
