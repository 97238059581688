import { getSortDirection } from '@root/helpers/sort'
import { endpoints } from '@root/transport/http/endpoints'
import { FilterComparator } from '@shared/models/charts/filter-comparator'
import { SortColumn } from '@shared/models/transactions/sort-column'
import { TransactionModel } from '@shared/models/transactions/transaction'
import { transactionStoreApi } from './store'

export const fetchTransactions = async () => {
  await new Promise((resolve) => setTimeout(resolve, 500))
  const {
    transactions,
    savedSearches
  } = await endpoints.transactions.getMany.dispatch()

  transactionStoreApi.setState({
    viewState: 'viewing',
    transactions,
    savedSearches
  })
}

export const addFilter = (column: SortColumn) =>
  transactionStoreApi.setState({
    filters: [
      ...transactionStoreApi.getState().filters,
      { column, comparator: 'equals', value: '' }
    ],
    selectedFilterColumn: column
  })

export const selectFilterColumn = (column: SortColumn) =>
  setTimeout(() =>
    transactionStoreApi.setState({ selectedFilterColumn: column })
  )

export const saveFilterColumn = () =>
  transactionStoreApi.setState({ selectedFilterColumn: undefined })

export const setFilterColumn = (column: SortColumn, newColumn: SortColumn) =>
  transactionStoreApi.setState({
    filters: transactionStoreApi.getState().filters.map((filter) => ({
      ...filter,
      column: filter.column === column ? newColumn : filter.column
    })),
    selectedFilterColumn: newColumn
  })

export const setFilterComparator = (
  column: SortColumn,
  comparator: FilterComparator
) =>
  transactionStoreApi.setState({
    filters: transactionStoreApi.getState().filters.map((filter) => ({
      ...filter,
      comparator: filter.column === column ? comparator : filter.comparator
    }))
  })

export const setFilterValue = (column: SortColumn, value: string) =>
  transactionStoreApi.setState({
    filters: transactionStoreApi.getState().filters.map((filter) => ({
      ...filter,
      value: filter.column === column ? value : filter.value
    }))
  })

export const removeFilter = (column: SortColumn) =>
  transactionStoreApi.setState({
    filters: transactionStoreApi
      .getState()
      .filters.filter((filter) => filter.column !== column)
  })

export const createNewSavedSearch = () =>
  transactionStoreApi.setState({
    newSavedSearchFilters: transactionStoreApi.getState().filters
  })

export const saveNewSavedSearch = async (name: string) => {
  const {
    savedSearches,
    newSavedSearchFilters
  } = transactionStoreApi.getState()

  if (name) {
    transactionStoreApi.setState({
      savedSearches: [
        ...savedSearches,
        await endpoints.transactions.createTransactionSearch.dispatch({
          id: 0,
          name,
          filters: newSavedSearchFilters
        })
      ],
      newSavedSearchFilters: []
    })
  } else {
    transactionStoreApi.setState({
      newSavedSearchFilters: []
    })
  }
}

export const activateSavedSearch = (id: number) => {
  const savedSearch = transactionStoreApi
    .getState()
    .savedSearches.find((_savedSearch) => _savedSearch.id === id)

  if (savedSearch) {
    transactionStoreApi.setState({ filters: savedSearch.filters })
  }
}

export const removeSavedSearch = async (id: number) => {
  await endpoints.transactions.removeTransactionSearch.dispatch(id)
  transactionStoreApi.setState({
    savedSearches: transactionStoreApi
      .getState()
      .savedSearches.filter((savedSearch) => savedSearch.id !== id)
  })
}

export const toggleVisibleColumn = (column: SortColumn) => {
  const { visibleColumns } = transactionStoreApi.getState()

  transactionStoreApi.setState({
    visibleColumns: visibleColumns.find((_column) => _column === column)
      ? visibleColumns.filter((_column) => _column !== column)
      : [...visibleColumns, column]
  })
}

export const selectTransactions = (
  ...transactions: Array<TransactionModel>
) => {
  const { selectedTransactions } = transactionStoreApi.getState()
  const previouslySelected = transactions.every((_transaction) =>
    selectedTransactions.find(
      /* eslint-disable-next-line @typescript-eslint/camelcase */
      (transaction_id) => _transaction.transaction_id === transaction_id
    )
  )

  transactionStoreApi.setState({
    selectedTransactions: [
      ...selectedTransactions.filter(
        /* eslint-disable-next-line @typescript-eslint/camelcase */
        (transaction_id) =>
          !transactions.find(
            /* eslint-disable-next-line @typescript-eslint/camelcase */
            (_transaction) => _transaction.transaction_id === transaction_id
          )
      ),
      ...(previouslySelected
        ? []
        : transactions.map((_transaction) => _transaction.transaction_id))
    ]
  })
}

export const sort = (sortColumn: SortColumn) => {
  const state = transactionStoreApi.getState()
  transactionStoreApi.setState({
    sortColumn,
    sortDirection: getSortDirection(
      state.sortColumn,
      sortColumn,
      state.sortDirection
    )
  })
}

export const setItemsPerPage = (itemsPerPage: number) =>
  transactionStoreApi.setState({ itemsPerPage, page: 1 })

export const setPage = (page: number) => transactionStoreApi.setState({ page })

export const nextPage = () =>
  transactionStoreApi.setState({
    page: transactionStoreApi.getState().page + 1
  })

export const previousPage = () =>
  transactionStoreApi.setState({
    page: transactionStoreApi.getState().page - 1
  })
