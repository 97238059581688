import { getValidationErrors, validate } from '@root/helpers/validation'
import { roleRules } from '@shared/helpers/validation'
import { useRoleStore } from '../../../store/store'
import { FieldsModel } from '../models/fields'
import { useRoleDialogStore } from './store'

export const useErrors = (): Record<keyof FieldsModel, string> => {
  const [fields, dirtyFields] = useRoleDialogStore((state) => [
    state.fields,
    state.dirtyFields
  ])

  return getValidationErrors(fields, dirtyFields, roleRules)
}

export const useCanSetStatus = () => {
  const selectedRoleId = useRoleStore((state) => state.selectedRoleId)
  const saving = useRoleDialogStore((state) => state.saving)

  return selectedRoleId && !saving
}

export const useCanSave = () => {
  const [saving, fields] = useRoleDialogStore((state) => [
    state.saving,
    state.fields
  ])

  return !saving && validate(fields, roleRules)
}

export const useOpen = () => {
  const activeDialog = useRoleStore((state) => state.activeDialog)

  return activeDialog === 'edit'
}

export const useAvailableDashboards = () => {
  const [organizationDashboards, dashboards] = useRoleDialogStore((state) => [
    state.organizationDashboards,
    state.dashboards
  ])

  return organizationDashboards.filter(
    (organizationDashboard) =>
      !dashboards.find((dashboard) => organizationDashboard.id === dashboard.id)
  )
}
