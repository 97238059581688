import { Box } from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/Launch'
import { OutlineButton } from '@root/components/outline-button'
import { Paper } from '@root/components/paper'
import { theme } from '@root/constants/theme'
import React from 'react'
import { useTransactionDetailStore } from '../../store/store'
import { FieldBlock } from './components/field-block'

export const Customer: React.FC<{}> = () => {
  const transaction = useTransactionDetailStore((state) => state.transaction)

  if (!transaction) {
    return null
  }

  return (
    <>
      <Box component={Paper} p={2} mt={15}>
        <Box
          py={1.5}
          px={2}
          mt={-17}
          bgcolor={theme.palette.blue()}
          color="rgba(255, 255, 255, 0.92)"
          borderRadius={8}
          fontFamily="Monospace"
        >
          <Box
            component="span"
            display="flex"
            justifyContent="flex-end"
            fontSize={16}
          >
            VISA
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            py={4}
            fontSize={16}
            letterSpacing={1}
          >
            <div>&#8226;&#8226;&#8226;&#8226;</div>
            <div>&#8226;&#8226;&#8226;&#8226;</div>
            <div>&#8226;&#8226;&#8226;&#8226;</div>
            <div>{transaction.cardDetails.lastDigits}</div>
          </Box>
          <Box display="flex" color="rgba(255, 255, 255, 0.8)" fontSize={10}>
            <Box mr={4}>{transaction.cardDetails.nameOnCard}</Box>
            <div>{transaction.cardDetails.expirationDate}</div>
          </Box>
        </Box>
        <Box
          pt={3}
          pb={1}
          color={theme.palette.dark()}
          fontSize={13}
          fontWeight={900}
          letterSpacing={0.81}
        >
          CUSTOMER
        </Box>
        <FieldBlock label="NAME" value={transaction.customer.name} />
        <FieldBlock label="E-MAIL" value={transaction.customer.email} />
        <FieldBlock label="PHONE" value={transaction.customer.phone} />
        <FieldBlock label="ADDRESS" value={transaction.customer.address} />
        <Box display="flex" justifyContent="flex-end" py={1}>
          <OutlineButton primary icon={<LaunchIcon />} iconRight>
            See customer page
          </OutlineButton>
        </Box>
      </Box>
    </>
  )
}
