import { Box, Grid, Typography } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import moment from 'moment'
import React from 'react'
import { TooltipProps } from 'recharts'
import { getLabelColor } from './label-color'

const formatValue = (value: string): string => `€ ${value}`

export const renderTooltip = (props: TooltipProps) => {
  let date = ''

  if (props.label) {
    date = moment(new Date(props.label)).format('MMM Do')
  }

  return (
    <Box
      p={2}
      boxShadow="0 20px 20px 0 rgba(0, 27, 66, 0.5)"
      borderRadius={6}
      color={theme.palette.white()}
      style={{ background: theme.palette.dark() }}
    >
      <Typography
        variant="body2"
        style={{ color: theme.palette.white('light'), fontWeight: 'bold' }}
      >
        {date}
      </Typography>
      <Grid container spacing={2}>
        {props.payload?.map((item, index) => (
          <Grid key={index} item>
            <Box display="flex" alignItems="center" mt={1}>
              <div
                style={{
                  width: 14,
                  height: 14,
                  borderRadius: '100%',
                  border: `3px solid ${getLabelColor(item.color || '')}`,
                  marginRight: 8
                }}
              />
              <Typography
                variant="body1"
                style={{ color: theme.palette.white('lighter') }}
              >
                {item.name}
              </Typography>
            </Box>
            <Box ml={2.5}>
              <Typography
                variant="subtitle2"
                style={{ color: theme.palette.white(), fontWeight: 'normal' }}
              >
                {item.value && formatValue(item.value.toString())}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
