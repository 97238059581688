import { Save } from '@material-ui/icons'
import { OutlineButton } from '@root/components/outline-button'
import { PageOverview } from '@root/components/page-overview'
import React from 'react'

interface Props {
  name: string
  save: () => void
  goBack: () => void
}

export const Overview: React.FC<Props> = ({ name, save, goBack }) => (
  <PageOverview
    title="Realtime Editor"
    subTitle={name || 'untitled.chart'}
    actionButton={
      <OutlineButton secondary icon={<Save />} onClick={save}>
        Save as new chart
      </OutlineButton>
    }
    goBack={goBack}
  />
)
