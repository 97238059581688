import { useLibraryStore } from './store'

export const useSelectedChart = () => {
  const [charts, selectedChartId] = useLibraryStore((state) => [
    state.charts,
    state.selectedChartId
  ])

  return charts.find((_chart) => _chart.id === selectedChartId)
}
