import { TextField } from '@material-ui/core'
import { Button } from '@root/components/button'
import { Dialog } from '@root/components/dialog/dialog'
import { OutlineButton } from '@root/components/outline-button'
import React, { useState } from 'react'
import { setViewing } from '../store/actions'
import { useRealtimeEditorStore } from '../store/store'

interface Props {
  save: (updatedName: string) => void
}

export const NameDialog: React.FC<Props> = ({ save }) => {
  const [name, setName] = useState('')
  const viewState = useRealtimeEditorStore((state) => state.viewState)

  return (
    <Dialog
      open={viewState === 'naming' || viewState === 'saving'}
      title="Save as new chart"
      content={
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      }
      actions={
        <>
          <OutlineButton secondary onClick={setViewing}>
            Cancel
          </OutlineButton>
          <Button primary onClick={() => save(name)}>
            Save
          </Button>
        </>
      }
      onClose={setViewing}
    />
  )
}
