import { getValidationErrors, validate } from '@root/helpers/validation'
import { roleRules } from '@shared/helpers/validation'
import { useDashboardStore } from '../../../store/store'
import { FieldsModel } from '../models/fields'
import { useScheduleReportDialogStore } from './store'

export const useErrors = (): Record<keyof FieldsModel, string> => {
  const [fields, dirtyFields] = useScheduleReportDialogStore((state) => [
    state.fields,
    state.dirtyFields
  ])

  return getValidationErrors(fields, dirtyFields, roleRules)
}

export const useCanSave = () => {
  const [saving, fields] = useScheduleReportDialogStore((state) => [
    state.saving,
    state.fields
  ])

  return !saving && validate(fields, roleRules)
}

export const useOpen = () => {
  const activeDialog = useDashboardStore((state) => state.activeDialog)

  return activeDialog === 'edit-report'
}
