import { Box, Fab } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { theme } from '@root/constants/theme'
import React from 'react'

interface Props {
  click: () => void
}

export const ActionButton: React.FC<Props> = ({ click }) => (
  <Box display="flex" justifyContent="flex-end" p={2}>
    <Box
      component={Fab}
      position="fixed"
      bottom={32}
      right={32}
      bgcolor={`${theme.palette.white()} !important`}
      border={`2px solid ${theme.palette.turquiose('light')}`}
      onClick={click}
    >
      <Box
        component={Add}
        fontSize={32}
        style={{ fill: theme.palette.green() }}
      />
    </Box>
  </Box>
)
