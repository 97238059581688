import { useDashboardStore } from './store'

export const useSelectedDashboard = () => {
  const [dashboards, selectedDashboardId] = useDashboardStore((state) => [
    state.dashboards,
    state.selectedDashboardId
  ])

  return dashboards.find((dashboard) => dashboard.id === selectedDashboardId)
}
