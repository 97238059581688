import { DashboardDataChartModel } from '@shared/models/charts/any-chart'
import { DashboardModel } from '@shared/models/dashboard'
import { useDashboardStore } from './store'

export const useCharts = (): Array<Array<DashboardDataChartModel>> => {
  const charts = useDashboardStore((state) => state.charts)

  return charts.reduce(
    (rows, chart) => {
      const lastRowIndex = rows.length - 1
      const lastRow = rows[lastRowIndex]

      if (!lastRow.length) {
        return rows.map((row, index) =>
          index === lastRowIndex ? [...row, chart] : row
        )
      } else if (lastRow.length && lastRow[lastRow.length - 1].linkedWithNext) {
        return rows.map((row, index) =>
          index === lastRowIndex ? [...row, chart] : row
        )
      } else {
        return [...rows, [chart]]
      }
    },
    [[]] as Array<Array<DashboardDataChartModel>>
  )
}

export const useSelectedDashboard = (): DashboardModel => {
  const [dashboards, selectedDashboardId] = useDashboardStore((state) => [
    state.dashboards,
    state.selectedDashboardId
  ])

  const dashboard = dashboards.find(
    (_dashboard) => _dashboard.id === selectedDashboardId
  )

  if (!dashboard) {
    return { id: 0, label: '' }
  }

  return dashboard
}

export const useSelectedChart = () => {
  const [charts, selectedDialogEntityId] = useDashboardStore((state) => [
    state.charts,
    state.selectedDialogEntityId
  ])

  return charts.find((_chart) => _chart.id === selectedDialogEntityId)
}
