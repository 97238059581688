import { setViewing } from '@root/components/chart-builder/store/actions'
import { endpoints } from '@root/transport/http/endpoints'
import {
  DataChartModel,
  LibraryDataChartModel
} from '@shared/models/charts/any-chart'
import { ActiveDialog } from './models/active-dialog'
import { initialState, libraryStoreApi } from './store'

export const reset = () => libraryStoreApi.setState({ ...initialState })
export const fetchLibrary = async () => {
  // TODO: Look at not having to reset libraries when going from chart builder -> library/charts
  libraryStoreApi.setState({ viewState: 'fetching' })
  libraryStoreApi.setState({
    viewState: 'library',
    charts: await endpoints.charts.get.library.dispatch()
  })
}

export const openChartBuilder = () =>
  libraryStoreApi.setState({
    viewState: 'chart-builder'
  })

export const closeChartBuilder = () =>
  libraryStoreApi.setState({
    viewState: 'library'
  })

export const openDialog = (
  activeDialog: ActiveDialog,
  selectedChartId?: number
) => libraryStoreApi.setState({ activeDialog, selectedChartId })

export const closeDialog = () =>
  libraryStoreApi.setState({ activeDialog: 'none' })

export const editChart = (selectedChartId: number) =>
  libraryStoreApi.setState({
    viewState: 'chart-builder',
    selectedChartId
  })

export const realtimeEditChart = (selectedChartId: number) =>
  libraryStoreApi.setState({
    viewState: 'realtime-editor',
    selectedChartId
  })

export const saveChart = async (chart: DataChartModel, saveNew = false) => {
  try {
    const { charts, selectedChartId } = libraryStoreApi.getState()
    const existingChart =
      !saveNew && charts.find((_chart) => _chart.id === selectedChartId)

    let newChart: LibraryDataChartModel

    if (existingChart) {
      newChart = await endpoints.charts.save.library.dispatch({
        ...chart,
        id: selectedChartId,
        name: existingChart.name
      })
    } else {
      newChart = await endpoints.charts.save.library.dispatch({
        ...chart,
        id: 0
      })
    }

    libraryStoreApi.setState({
      viewState: 'library',
      charts: selectedChartId
        ? charts.map((_chart) =>
            _chart.id === selectedChartId
              ? { ..._chart, ...chart, id: selectedChartId }
              : _chart
          )
        : [...charts, newChart],
      selectedChartId: undefined
    })
  } catch (e) {
    // TODO: Should be handled in realtime editor?
    setViewing()
    throw e
  }
}

export const duplicateChart = async (chart: LibraryDataChartModel) =>
  saveChart({ ...chart, id: 0, name: `${chart.name} (copy)` })

export const renameChart = async (name: string) => {
  const { charts, selectedChartId } = libraryStoreApi.getState()

  if (selectedChartId) {
    await endpoints.charts.manage.rename.dispatch({ id: selectedChartId, name })

    libraryStoreApi.setState({
      activeDialog: 'none',
      charts: charts.map((chart) =>
        chart.id === selectedChartId ? { ...chart, name } : chart
      )
    })
  }
}

export const removeChart = async () => {
  const { selectedChartId } = libraryStoreApi.getState()

  if (selectedChartId) {
    await endpoints.charts.manage.remove.dispatch(selectedChartId)

    libraryStoreApi.setState({
      charts: libraryStoreApi
        .getState()
        .charts.filter((chart) => chart.id !== selectedChartId),
      activeDialog: 'none'
    })
  }
}

export const fetchDashboards = async () =>
  libraryStoreApi.setState({
    dashboards: (await endpoints.dashboards.getAll.dispatch(undefined))
      .dashboards
  })

export const addToDashboard = async (dashboardId: number) => {
  const { selectedChartId } = libraryStoreApi.getState()

  if (selectedChartId) {
    await endpoints.charts.manage.addToDashboard.dispatch({
      id: selectedChartId,
      dashboardId
    })
    libraryStoreApi.setState({ activeDialog: 'none' })
  }
}
