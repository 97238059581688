import { theme } from '@root/constants/theme'
import { TimelineData } from '@shared/models/charts/any-chart'
import moment from 'moment'
import React from 'react'
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { renderGradients } from './helpers/render-gradients'
import { renderLegend } from './helpers/render-legend'
import { renderTooltip } from './helpers/render-tooltip'
import { tickFormatter } from './helpers/tick-formatter'

interface Props {
  data: TimelineData
}

const colors: Array<'blue' | 'orange' | 'turquiose'> = [
  'blue',
  'turquiose',
  'orange'
]

export const HorizontalBarChart: React.FC<Props> = ({ data }) => {
  if (!data.labels.length || !data.dataList.every((item) => item.date)) {
    throw new Error('Invalid data')
  }

  return (
    // TODO: Consider a better way to eliminate the 30px margin from recharts
    <div style={{ width: 'calc(100% + 30px)', height: '475px' }}>
      <ResponsiveContainer>
        <BarChart
          data={data.dataList}
          margin={{
            top: 10,
            right: 30,
            left: 30,
            bottom: 0
          }}
          layout="vertical"
        >
          {renderGradients()}
          {renderLegend()}
          <XAxis
            type="number"
            tickFormatter={tickFormatter}
            tickCount={6}
            tick={{
              fontSize: 18,
              fill: theme.palette.dark('lighter')
            }}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            type="category"
            dataKey="date"
            tickFormatter={(timeStr) => `${moment(timeStr).format('MMM Do')}`}
            tick={{
              fontSize: 18,
              fill: theme.palette.dark('lighter')
            }}
            interval={Math.round(data.dataList.length / 8)}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip content={renderTooltip} />
          {data.labels.map((label, index) => (
            <Bar
              key={index}
              name={label.name}
              dataKey={label.key}
              fill={`url(#${colors[index]})`}
              opacity="1"
              radius={2}
              barSize={35}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
