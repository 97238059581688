import { Box, Grid } from '@material-ui/core'
import { BigChart } from '@root/components/charts/big-chart'
import { useChartImage } from '@root/helpers/chart-image'
import { LibraryDataChartModel } from '@shared/models/charts/any-chart'
import React from 'react'

interface Props {
  chart: LibraryDataChartModel
  duplicate?: () => void
  addToDashboard?: () => void
  rename?: () => void
  edit?: () => void
  realtimeEdit?: () => void
  remove?: () => void
}

export const Chart: React.FC<Props> = ({
  chart,
  duplicate,
  addToDashboard,
  edit,
  realtimeEdit,
  rename,
  remove
}) => {
  const queryImage = useChartImage('query', chart.queryImage)
  const typeImage = useChartImage('type', chart.typeImage)

  return (
    <Grid item xs={4}>
      <BigChart
        name={chart.name}
        subtitle={chart.queryName}
        duplicate={duplicate}
        addToDashboard={addToDashboard}
        rename={rename}
        edit={edit}
        realtimeEdit={realtimeEdit}
        remove={remove}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <img alt="query" src={queryImage} />
            </Grid>
            <Grid item>
              <img alt="chart-type" src={typeImage} />
            </Grid>
          </Grid>
          <Box position="absolute" right={24} fontSize={14} fontStyle="italic">
            on {chart.dashboards} dashboards
          </Box>
        </Box>
      </BigChart>
    </Grid>
  )
}
