import { FormField } from '@root/components/form-field'
import { getFormLabel } from '@root/helpers/store/form-label'
import React from 'react'
import { FieldsModel } from '../models/fields'
import {
  setDirtyField,
  setField,
  setPreviousDirtyFields
} from '../store/actions'
import { useScheduleReportDialogStore } from '../store/store'

interface Props {
  field: keyof FieldsModel
  errors: Record<keyof FieldsModel, string>
  required?: boolean
  autoFocus?: boolean
}

export const Field: React.FC<Props> = ({
  field,
  errors,
  required,
  autoFocus,
  children
}) => {
  const [saving, value] = useScheduleReportDialogStore((state) => [
    state.saving,
    state.fields[field]
  ])

  return (
    <FormField
      label={getFormLabel(field)}
      value={value}
      required={required!}
      error={errors[field]}
      disabled={saving}
      autoFocus={autoFocus}
      setField={(_value) => setField({ [field]: _value })}
      setDirtyField={() => setDirtyField(field)}
      setPreviousDirtyFields={() => setPreviousDirtyFields(field)}
    >
      {children}
    </FormField>
  )
}
