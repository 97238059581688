import { includesName, sortByColumn } from '@root/pages/team/helpers/store'
import { UserModel } from '@shared/models/user/user'
import { useUserStore } from './store'

export const useUsers = (): Array<UserModel> => {
  const [
    users,
    searchQuery,
    sortColumn,
    sortDirection
  ] = useUserStore((state) => [
    state.users,
    state.searchQuery,
    state.sortColumn,
    state.sortDirection
  ])

  return users
    .filter((user) => includesName(user.email, searchQuery))
    .sort((a, b) => sortByColumn(sortColumn, sortDirection, a, b))
}
