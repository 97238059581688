import { Tabs } from '@root/components/tabs'
import { navigate, usePath } from 'hookrouter'
import React, { useEffect, useState } from 'react'

const getCurrentTab = (path: string): string =>
  `/${path.split('/').filter(Boolean)[0]}`

export const MenuTabs: React.FC<{}> = () => {
  const [tab, setTab] = useState()
  const path = usePath()

  useEffect(() => {
    setTimeout(() => setTab(getCurrentTab(path)), tab === '' ? 250 : 0)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [path])

  return (
    <Tabs
      tabs={[
        { label: 'Dashboard', value: '/dashboard' },
        { label: 'Library', value: '/library' },
        { label: 'Transactions', value: '/transactions' }
      ]}
      currentTab={tab}
      size="large"
      setTab={(value) => navigate(value)}
    />
  )
}
