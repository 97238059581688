import { getSortDirection } from '@root/pages/team/helpers/store'
import { endpoints } from '@root/transport/http/endpoints'
import { RoleModel } from '@shared/models/role/role'
import { UserStatus } from '@shared/models/user/status'
import { ActiveDialog } from '../models/active-dialog'
import { SortColumn } from '../models/sort-column'
import { userStoreApi } from './store'

export const fetchUsers = async () => {
  const { users, merchants } = await endpoints.users.getAll.dispatch()

  userStoreApi.setState({
    viewState: 'viewing',
    users,
    merchants
  })
}

export const resetUsers = () => userStoreApi.setState({ viewState: 'fetching' })

export const setSearchQuery = (searchQuery: string) =>
  userStoreApi.setState({ searchQuery })

export const sort = (sortColumn: SortColumn) => {
  const state = userStoreApi.getState()
  userStoreApi.setState({
    sortColumn,
    sortDirection: getSortDirection(
      state.sortColumn,
      sortColumn,
      state.sortDirection
    )
  })
}

export const openDialog = (
  activeDialog: ActiveDialog,
  selectedUserId?: number
) => userStoreApi.setState({ activeDialog, selectedUserId })

export const closeDialog = () => userStoreApi.setState({ activeDialog: 'none' })

export const updateUserStatus = async (status: UserStatus) => {
  const { users, selectedUserId } = userStoreApi.getState()
  userStoreApi.setState({
    users: users.map((user) =>
      user.id === selectedUserId ? { ...user, status } : user
    )
  })
}

export const saveUser = async (
  email: string,
  singleSignOn: string,
  roles: Array<RoleModel>,
  merchants: Array<string>
) => {
  const { selectedUserId } = userStoreApi.getState()

  const user = await endpoints.users.save.dispatch({
    id: selectedUserId,
    email,
    singleSignOn,
    roles: roles.map((role) => role.id),
    merchants
  })

  const { users } = userStoreApi.getState()

  userStoreApi.setState({
    users: selectedUserId
      ? users.map((_user) => (_user.id === user.id ? user : _user))
      : [...users, user],
    activeDialog: 'none'
  })
}

export const deleteUser = async () => {
  const { selectedUserId } = userStoreApi.getState()

  if (selectedUserId) {
    await endpoints.users.remove.dispatch(selectedUserId)
    userStoreApi.setState({
      users: userStoreApi
        .getState()
        .users.filter((user) => user.id !== selectedUserId),
      activeDialog: 'none'
    })
  }
}
