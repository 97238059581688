import React from 'react'
import { SearchField } from '../../components/search-field'
import { setSearchQuery } from '../store/actions'
import { useUserStore } from '../store/store'

export const UserSearch = () => {
  const searchQuery = useUserStore((state) => state.searchQuery)

  return (
    <SearchField
      label="Search users"
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  )
}
