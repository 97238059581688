import { usePath, useRoutes } from 'hookrouter'
import React from 'react'
import { TransactionDetail } from './pages/transaction-detail/transaction-detail'
import { TransactionList } from './pages/transaction-list/transaction-list'

const routes = {
  '/': () => <TransactionList />,
  '/:transactionId': () => <TransactionDetail />
}

export const Transactions = () => {
  const routeResult = useRoutes(routes)
  usePath()

  return <div>{routeResult || <div>no subview found</div>}</div>
}
