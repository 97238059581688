import { useEffect, useState } from 'react'

export const useChartImage = (variant: 'type' | 'query', image: string) => {
  const [imageRef, setImageRef] = useState('')

  useEffect(() => {
    import(
      `@root/assets/images/${
        variant === 'type' ? 'chart-types' : 'chart-queries'
      }/${image}`
    ).then((_image) => setImageRef(_image.default))
  }, [variant, image])

  return imageRef
}
