import { Grid, Typography } from '@material-ui/core'
import { brand } from '@root/constants/brand'
import React from 'react'

export const Access: React.FC<{}> = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="h3">Select one of the following methods</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="h5">
        Supply access credentials to existing BigQuery database
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          Either provide the access credentials to an up and running BigQuery
          database, allowing {brand === 'generation' ? 'generation' : 'fourbee'}{' '}
          to connect and read data from the BigQuery database.
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="h5">
        Supply access credentials to a Google Cloud account
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          Or provide the access credentials to a Google Cloud account with
          administrator privileges, allowing{' '}
          {brand === 'generation' ? 'generation' : 'fourbee'} to login and setup
          a BigQuery database and connect it to the app.
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)
