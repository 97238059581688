import { Box, Grid } from '@material-ui/core'
import { Button } from '@root/components/button'
import { Dialog } from '@root/components/dialog/dialog'
import { ErrorButton } from '@root/components/error-button'
import React, { useEffect } from 'react'
import { closeDialog } from '../../store/actions'
import { ColumnFilters } from './components/column-filters'
import { Dashboards } from './components/dashboards'
import { Field } from './components/field'
import { Restrictions } from './components/restrictions'
import { init, reset, save, setStatus } from './store/actions'
import {
  useCanSave,
  useCanSetStatus,
  useErrors,
  useOpen
} from './store/selectors'
import { useRoleDialogStore } from './store/store'

export const RoleDialog: React.FC = () => {
  const errors = useErrors()
  const canSetStatus = useCanSetStatus()
  const canSave = useCanSave()
  const open = useOpen()
  const [onlyShowDashboard, status] = useRoleDialogStore((state) => [
    state.onlyShowDashboard,
    state.status
  ])

  useEffect(() => {
    open && init()

    return () => {
      !open && reset()
    }
  }, [open])

  return (
    <Dialog
      open={open}
      large
      title=""
      content={
        <Box py={2}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Field field="name" errors={errors} required autoFocus />
            </Grid>
            <Grid item xs={12}>
              <ColumnFilters />
            </Grid>
            <Grid item xs={12}>
              <Restrictions />
            </Grid>
            {onlyShowDashboard && (
              <Grid item xs={12}>
                <Dashboards />
              </Grid>
            )}
          </Grid>
        </Box>
      }
      actions={
        <>
          <ErrorButton disabled={!canSetStatus} onClick={setStatus}>
            {status === 'active' ? 'Block role' : 'Unblock role'}
          </ErrorButton>
          <Button primary disabled={!canSave} onClick={save}>
            Save changes
          </Button>
        </>
      }
      onClose={closeDialog}
    />
  )
}
