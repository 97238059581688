import { DataChartModel } from '@shared/models/charts/any-chart'
import { useRealtimeEditorStore } from './store'

export const useChart = (): DataChartModel => {
  const [
    id,
    name,
    variant,
    queryId,
    advancedQueryId,
    typeId,
    fromDateType,
    fromDateValue,
    toDate,
    top,
    filters,
    data
  ] = useRealtimeEditorStore((state) => [
    state.id,
    state.name,
    state.variant,
    state.queryId,
    state.advancedQueryId,
    state.typeId,
    state.fromDateType,
    state.fromDateValue,
    state.toDate,
    state.top,
    state.filters,
    state.data
  ])

  return {
    id: id || 0,
    name,
    typeId,
    method: 'regular',
    variant,
    queryId,
    advancedQueryId,
    customQuery: '',
    fromDateType,
    fromDateValue,
    toDate,
    top,
    filters,
    data
  }
}
