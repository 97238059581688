import { PageHeader } from '@root/components/page-header'
import { Tabs } from '@root/components/tabs'
import { setTab, useTabStore } from '@root/store/tab'
import { navigate, usePath, useRoutes } from 'hookrouter'
import React, { useEffect } from 'react'
import { Roles } from './pages/roles/roles'
import { Users } from './pages/users/users'

const routes = {
  users: () => <Users />,
  roles: () => <Roles />
}

const getCurrentTab = (path: string): string => {
  if (path.startsWith('/team/users')) {
    return 'users'
  } else if (path.startsWith('/team/roles')) {
    return 'roles'
  }

  return ''
}

export const Team: React.FC<{}> = () => {
  const tab = useTabStore((state) => state.tab)
  const routeResult = useRoutes(routes)
  const path = usePath()

  useEffect(() => {
    setTab(getCurrentTab(path))
  }, [path])

  return (
    <>
      <PageHeader
        title="My team"
        tabs={
          <Tabs
            tabs={[
              { label: 'Users', value: 'users' },
              { label: 'Roles', value: 'roles' }
            ]}
            currentTab={tab}
            size="small"
            setTab={(value) => navigate(`/team/${value}`)}
          />
        }
      />
      <div>{routeResult || <div>no subview found</div>}</div>
    </>
  )
}
