import { endpoints } from '@root/transport/http/endpoints'
import { DashboardColor, DashboardType } from '@shared/models/dashboard'
import { ActiveDialog } from './models/active-dialog'
import { dashboardStoreApi, initialState } from './store'

export const reset = () => dashboardStoreApi.setState({ ...initialState })
export const fetchDashboards = async () => {
  dashboardStoreApi.setState({
    viewState: 'viewing',
    dashboards: (await endpoints.dashboards.getAll.dispatch(undefined))
      .dashboards
  })
}

export const openDialog = (
  activeDialog: ActiveDialog,
  selectedDashboardId?: number
) => dashboardStoreApi.setState({ activeDialog, selectedDashboardId })

export const closeDialog = () =>
  dashboardStoreApi.setState({ activeDialog: 'none' })

export const saveDashboard = async (
  id: number,
  name: string,
  description: string,
  type: DashboardType,
  color: DashboardColor
) => {
  const dashboard = await endpoints.dashboards.save.dispatch({
    id: id || undefined,
    name,
    description,
    type,
    color
  })
  dashboardStoreApi.setState({
    activeDialog: 'none',
    dashboards: id
      ? dashboardStoreApi
          .getState()
          .dashboards.map((_dashboard) =>
            _dashboard.id === id ? dashboard : _dashboard
          )
      : [...dashboardStoreApi.getState().dashboards, dashboard]
  })
}

export const renameDashboard = async (name: string) => {
  const { dashboards, selectedDashboardId } = dashboardStoreApi.getState()

  if (selectedDashboardId) {
    await endpoints.dashboards.rename.dispatch({
      id: selectedDashboardId,
      name
    })

    dashboardStoreApi.setState({
      activeDialog: 'none',
      dashboards: dashboards.map((dashboard) =>
        dashboard.id === selectedDashboardId
          ? { ...dashboard, label: name }
          : dashboard
      )
    })
  }
}

export const removeDashboard = async () => {
  const { selectedDashboardId } = dashboardStoreApi.getState()

  if (selectedDashboardId) {
    await endpoints.dashboards.remove.dispatch(selectedDashboardId)

    dashboardStoreApi.setState({
      dashboards: dashboardStoreApi
        .getState()
        .dashboards.filter((dashboard) => dashboard.id !== selectedDashboardId),
      activeDialog: 'none'
    })
  }
}

export const reorderChart = (from: number, to: number) => {
  if (from !== to) {
    const { id } = dashboardStoreApi.getState().dashboards[from]
    const dashboards = dashboardStoreApi.getState().dashboards.slice()
    const [removed] = dashboards.splice(from, 1)
    dashboards.splice(to, 0, removed)

    dashboardStoreApi.setState({ dashboards })
    endpoints.dashboards.reorder.dispatch({ id, order: to + 1 })
  }
}
