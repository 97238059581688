import { ActionButton } from '@root/components/action-button'
import { ChartBuilder } from '@root/components/chart-builder/chart-builder'
import { Loader } from '@root/components/loader'
import { RealtimeEditor } from '@root/components/realtime-editor/realtime-editor'
import { setTab } from '@root/store/tab'
import React, { useEffect } from 'react'
import { DeleteChartDialog } from './components/delete-chart-dialog'
import { Edit } from './components/edit/edit'
import { RenameChartDialog } from './components/rename-chart-dialog'
import { ScheduleReportDialog } from './components/schedule-report-dialog/schedule-report-dialog'
import { View } from './components/view'
import {
  openChartBuilder,
  fetchDashboards,
  openDialog,
  reset,
  resetViewState,
  saveChart
} from './store/actions'
import { useSelectedChart } from './store/selectors'
import { useDashboardStore } from './store/store'

interface Props {
  id?: string
}

export const Dashboard: React.FC<Props> = ({ id }) => {
  const [
    viewState,
    dashboards,
    selectedDashboardId
  ] = useDashboardStore((state) => [
    state.viewState,
    state.dashboards,
    state.selectedDashboardId
  ])
  const selectedChart = useSelectedChart()

  useEffect(() => {
    setTab(id || '')
    fetchDashboards(Number(id))

    return reset
  }, [id])

  return (
    <>
      {viewState === 'chart-builder' && (
        <ChartBuilder
          type="dashboard"
          initialDashboardId={selectedDashboardId}
          dashboards={dashboards}
          chart={selectedChart}
          goBack={resetViewState}
          save={(chart, dashboardId) => saveChart(chart, false, dashboardId)}
          remove={() => openDialog('delete-chart', selectedChart?.id)}
        />
      )}
      {viewState === 'realtime-edit' && (
        <RealtimeEditor
          type="dashboard"
          dashboards={[]}
          chart={selectedChart}
          goBack={resetViewState}
          save={(_chart) => saveChart(_chart, true)}
        />
      )}
      {viewState !== 'chart-builder' && viewState !== 'realtime-edit' && (
        <>
          {viewState === 'fetching-all' && <Loader />}
          {viewState !== 'fetching-all' && (
            <>
              {viewState === 'fetching-dashboard' && <Loader />}
              {viewState === 'viewing' && <View />}
              {(viewState === 'edit-order' || viewState === 'edit-reports') && (
                <Edit />
              )}
              <ActionButton click={openChartBuilder} />
            </>
          )}
        </>
      )}
      <RenameChartDialog />
      <DeleteChartDialog />
      <ScheduleReportDialog />
    </>
  )
}
