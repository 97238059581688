import { Typography } from '@material-ui/core'
import { Button } from '@root/components/button'
import { OutlineButton } from '@root/components/outline-button'
import React from 'react'
import { Dialog } from './dialog'

interface Props {
  open: boolean
  type: string
  variant?: 'delete' | 'remove'
  close: () => void
  remove: () => void
}

export const DeleteDialog: React.FC<Props> = ({
  open,
  type,
  variant = 'delete',
  close,
  remove
}) => {
  const capitalVariant = `${variant[0].toUpperCase()}${variant.slice(1)}`

  return (
    <Dialog
      open={open}
      title={`${capitalVariant} ${type}`}
      content={
        <Typography variant="body1">
          Are you sure you want to {variant} this {type}?{' '}
          {variant === 'delete' && 'The effects cannot be reverted.'}
        </Typography>
      }
      actions={
        <>
          <OutlineButton secondary onClick={close}>
            Cancel
          </OutlineButton>
          <Button error onClick={remove}>
            {capitalVariant}
          </Button>
        </>
      }
      onClose={close}
    />
  )
}
