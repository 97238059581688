import { ListItemIcon, MenuItem, Typography } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import { DropdownMenu } from '@root/components/dropdown/dropdown-menu'
import React from 'react'
import { openDialog } from '../../../store/actions'

interface Props {
  anchorEl: HTMLElement | null
  selectedUserId: number
  close: () => void
}

export const UserMenu: React.FC<Props> = ({
  anchorEl,
  selectedUserId,
  close
}) => {
  const handleClick = (callback: () => void) => {
    callback()
    close()
  }

  return (
    <DropdownMenu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={close}
      onClick={close}
    >
      <MenuItem
        onClick={() => handleClick(() => openDialog('edit', selectedUserId))}
      >
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <Typography variant="inherit">Edit user</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => handleClick(() => openDialog('delete', selectedUserId))}
      >
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <Typography variant="inherit">Delete user</Typography>
      </MenuItem>
    </DropdownMenu>
  )
}
