import { MutableRefObject, useEffect, useState } from 'react'
import { legendClassName } from '../constants/legend-class-name'

export const useLegendHeight = (
  ref: MutableRefObject<HTMLDivElement | null>
) => {
  const [legendHeight, setLegendHeight] = useState(0)

  useEffect(() => {
    setTimeout(() =>
      setLegendHeight(
        ref?.current?.querySelector(`.${legendClassName}`)?.clientHeight || 0
      )
    )
  }, [ref])

  return legendHeight
}
