import { Grid } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import { ChartRenderer } from '@root/components/charts/chart-renderer'
import { OutlineButton } from '@root/components/outline-button'
import { PageHeader } from '@root/components/page-header'
import React from 'react'
import {
  duplicateChart,
  editChart,
  openDialog,
  openRealtimeEdit,
  toggleEditing
} from '../store/actions'
import { useCharts } from '../store/selectors'
import { DashboardTabs } from './dashboard-tabs'

export const View: React.FC<{}> = () => {
  const charts = useCharts()

  return (
    <>
      <PageHeader
        title="Dashboard, here's your stats."
        tabs={<DashboardTabs />}
        action={
          <>
            <OutlineButton secondary icon={<Menu />} onClick={toggleEditing}>
              Edit dashboard
            </OutlineButton>
          </>
        }
      />
      <Grid container spacing={3}>
        {charts.map((row) =>
          row.map((chart) => (
            <ChartRenderer
              key={chart.id}
              chart={chart}
              size={(12 / row.length) as 4 | 6 | 12}
              duplicate={() => duplicateChart(chart)}
              edit={() => editChart(chart.id)}
              schedule={() => openDialog('edit-report', chart.id)}
              rename={() => openDialog('rename-chart', chart.id)}
              removeFromDashboard={() => openDialog('delete-chart', chart.id)}
              realtimeEdit={() => openRealtimeEdit(chart.id)}
            />
          ))
        )}
      </Grid>
    </>
  )
}
