import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { DeleteRoleDialog } from './components/delete-dialog'
import { RoleDialog } from './components/role-dialog/role-dialog'
import { RoleSearch } from './components/role-search'
import { RoleTable } from './components/role-table/role-table'
import { fetchRoles, resetRoles } from './store/actions'

export const Roles = () => {
  useEffect(() => {
    fetchRoles()
    return resetRoles
  }, [])

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <RoleSearch />
        </Grid>
        <Grid item xs={12}>
          <RoleTable />
        </Grid>
      </Grid>
      <DeleteRoleDialog />
      <RoleDialog />
    </>
  )
}
