import { TextField } from '@material-ui/core'
import { Button } from '@root/components/button'
import { Dialog } from '@root/components/dialog/dialog'
import { OutlineButton } from '@root/components/outline-button'
import { DataChartModel } from '@shared/models/charts/any-chart'
import React, { useState } from 'react'
import { closeDialog, nameChart } from '../store/actions'
import { initialState, useChartBuilderStore } from '../store/store'

interface Props {
  save: (chart: DataChartModel) => void
}

export const NameDialog: React.FC<Props> = ({ save }) => {
  const [currentStep, customQuery] = useChartBuilderStore((state) => [
    state.currentStep,
    state.customQuery
  ])
  // TODO: The result of try/catching ChartRenderer
  const canRenderChart = customQuery === initialState.customQuery
  const [name, setName] = useState('')

  return (
    <Dialog
      open={canRenderChart && currentStep === 'name-chart'}
      title="Set chart name"
      content={
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      }
      actions={
        <>
          <OutlineButton secondary onClick={closeDialog}>
            Cancel
          </OutlineButton>
          <Button primary onClick={() => nameChart(name, save)}>
            Save
          </Button>
        </>
      }
      onClose={closeDialog}
    />
  )
}
