import { PageOverview } from '@root/components/page-overview'
import { navigate } from 'hookrouter'
import React from 'react'

export const Overview = () => (
  <PageOverview
    title="Transaction details"
    subTitle="0th-period-1001-4f287dcd5eff4498"
    goBack={() => navigate('/transactions')}
  />
)
