import React from 'react'
import { useChartBuilderStore } from '../../store/store'
import { Custom } from './components/custom'
import { Regular } from './components/regular'
import { Advanced } from './components/advanced'

export const WhatToSee: React.FC<{}> = () => {
  const method = useChartBuilderStore((store) => store.method)

  return (
    <>
      {method === 'regular' && <Regular />}
      {method === 'custom' && <Custom />}
      {method === 'advanced' && <Advanced />}
    </>
  )
}
