import { includesName, sortByColumn } from '@root/pages/team/helpers/store'
import { RoleDetailModel } from '@shared/models/role/detail'
import { useRoleStore } from './store'

export const useRoles = (): Array<RoleDetailModel> => {
  const [
    roles,
    searchQuery,
    sortColumn,
    sortDirection
  ] = useRoleStore((state) => [
    state.roles,
    state.searchQuery,
    state.sortColumn,
    state.sortDirection
  ])

  return roles
    .filter((role) => includesName(role.name, searchQuery))
    .sort((a, b) => sortByColumn(sortColumn, sortDirection, a, b))
}
