import { Grid, Typography } from '@material-ui/core'
import { brand } from '@root/constants/brand'
import React from 'react'

export const Ready: React.FC<{}> = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="h3">
        {brand === 'generation' ? 'Generation' : 'Fourbee'} ready!
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {brand === 'generation' ? 'Generation' : 'Fourbee'} is now ready,
          login to enjoy the experience
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)
