import { DeleteDialog } from '@root/components/dialog/delete-dialog'
import React from 'react'
import { closeDialog, deleteUser } from '../store/actions'
import { useUserStore } from '../store/store'

export const DeleteUserDialog: React.FC = () => {
  const [activeDialog] = useUserStore((state) => [state.activeDialog])

  return (
    <DeleteDialog
      open={activeDialog === 'delete'}
      type="user"
      close={closeDialog}
      remove={deleteUser}
    />
  )
}
