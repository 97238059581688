import {
  Grid,
  Table,
  Toolbar,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableContainer,
  Paper
} from '@material-ui/core'
import { SynchronizeTable } from '@shared/models/synchronize-table'
import React from 'react'

export const DataTable: React.FC<{ table: SynchronizeTable }> = ({ table }) => (
  <Grid item xs={4}>
    <Paper>
      <Toolbar variant="dense">
        <Typography variant="h4">{table.name}</Typography>
      </Toolbar>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {table.columns.map((column, columnIndex) => (
                <TableCell key={columnIndex}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {table.data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <TableCell key={cellIndex}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  </Grid>
)
