import { Button, Grid, Typography } from '@material-ui/core'
import { goBack } from '@root/store/auth/actions'
import React from 'react'

export const EmailLoginMessage: React.FC = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography component="p">
        Please check your mail and login with the link in the message.
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Button color="primary" variant="outlined" fullWidth onClick={goBack}>
        Go back
      </Button>
    </Grid>
  </Grid>
)
