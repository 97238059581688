import { Grid, MenuItem, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { Dropdown } from '@root/components/dropdown/dropdown'
import { FormField } from '@root/components/form-field'
import { OutlineButton } from '@root/components/outline-button'
import React from 'react'
import {
  addDashboard,
  removeDashboard,
  updateColumnFilter
} from '../store/actions'
import { useAvailableDashboards } from '../store/selectors'
import { useRoleDialogStore } from '../store/store'

export const Dashboards: React.FC = () => {
  const dashboards = useRoleDialogStore((state) => state.dashboards)
  const availableDashboards = useAvailableDashboards()

  return (
    <Grid container spacing={2}>
      {dashboards.map((dashboard, index) => (
        <Grid key={index} item xs={3}>
          <FormField
            label="Dashboard name"
            value={dashboard.label}
            required={false}
            disabled
            setField={(value) => updateColumnFilter(index, 'column', value)}
            remove={() => removeDashboard(dashboard)}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Dropdown
          button={
            <OutlineButton
              primary
              icon={<Add />}
              iconRight
              disabled={!availableDashboards.length}
            >
              Add dashboard
            </OutlineButton>
          }
        >
          {availableDashboards.map((dashboard, index) => (
            <MenuItem key={index} onClick={() => addDashboard(dashboard)}>
              <Typography variant="inherit">{dashboard.label}</Typography>
            </MenuItem>
          ))}
        </Dropdown>
      </Grid>
    </Grid>
  )
}
