import { theme } from '@root/constants/theme'

// TODO: Use more generic color names for the gradients instead of grossRevenue and sales
export const getLabelColor = (color: string): string => {
  switch (color) {
    case 'url(#blue)':
      return theme.palette.blue()
    case 'url(#turquiose)':
      return theme.palette.turquiose()
    case 'url(#orange)':
      return theme.palette.orange()
    case 'url(#green)':
      return theme.palette.green()
    case 'url(#dark)':
      return theme.palette.dark('light')
    default:
      return color
  }
}
