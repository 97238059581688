import { DeleteDialog } from '@root/components/dialog/delete-dialog'
import {
  closeDialog,
  deleteDashboard
} from '@root/pages/dashboard/store/actions'
import { useDashboardStore } from '@root/pages/dashboard/store/store'
import React from 'react'

export const DeleteDashboardDialog: React.FC<{}> = () => {
  const [activeDialog] = useDashboardStore((state) => [state.activeDialog])

  return (
    <DeleteDialog
      open={activeDialog === 'delete-dashboard'}
      type="dashboard"
      close={closeDialog}
      remove={deleteDashboard}
    />
  )
}
