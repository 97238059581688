import { theme } from '@root/constants/theme'
import React from 'react'

export const renderGradients = () => (
  <defs>
    <linearGradient id="blue" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor={theme.palette.blue('light')} />
      <stop offset="98%" stopColor={theme.palette.blue()} />
    </linearGradient>
    <linearGradient id="turquiose" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor={theme.palette.turquiose('light')} />
      <stop offset="98%" stopColor={theme.palette.turquiose()} />
    </linearGradient>
    <linearGradient id="orange" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor={theme.palette.orange('light')} />
      <stop offset="98%" stopColor={theme.palette.orange()} />
    </linearGradient>
    <linearGradient id="green" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor={theme.palette.green('light')} />
      <stop offset="98%" stopColor={theme.palette.green()} />
    </linearGradient>
    <linearGradient id="dark" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor={theme.palette.dark('lighter')} />
      <stop offset="98%" stopColor={theme.palette.dark('light')} />
    </linearGradient>

    <filter id="f3" x="0" y="0" width="100%" height="100%">
      <feOffset result="offOut" in="SourceAlpha" dx="5" dy="20" />
      <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
      <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
    </filter>
  </defs>
)
