import { transactionDetailStoreApi } from './store'

export const fetchTransaction = async () => {
  await new Promise((resolve) => setTimeout(resolve, 500))

  /* eslint-disable-next-line spaced-comment */
  /*transactionDetailStoreApi.setState({
    viewState: 'viewing',
    transaction: {
      status: 'completed',
      transactionNumber: 'QE-Auto-Payment-1',
      scheme: 'visa',
      terminalDate: '23 may 2018 08:34:38 PM',
      amount: '€52.59',
      pan: '3498',
      store: 'Stage Stores',
      processor: 'Babs Paylink',
      details: {
        invoiceId: undefined,
        available: '€0.00',
        createdOn: '3 hours ago',
        fees: '€0.00',
        shipping: '€0.00',
        taxes: '€0.00',
        avsCheck: 'completed',
        currency: 'EUR',
        paymentType: 'Card',
        eci: undefined,
        mcc: undefined,
        merchantAccount: undefined,
        acquirer: undefined,
        isOnUs: undefined,
        externalGatewayUsed: 'Babs Paylink'
      },
      cardDetails: {
        nameOnCard: 'Andreas Östberg',
        lastDigits: '9022',
        iin: '374957',
        bankName: 'AMEX France Globestar Charge',
        type: 'credit',
        category: 'consumer',
        expirationDate: '03/2020',
        scheme: 'visa',
        coScheme: undefined,
        brand: undefined,
        country: 'sweden',
        address: 'Stockholm, SE',
        avsCheck: 'pending',
        cvcCheck: 'completed',
        threeDSecure: 'failed'
      },
      customer: {
        name: 'Andreas Östberg',
        email: 'and.ostberg@gmail.com',
        phone: '0043 123 441 32 54',
        address: `Sveavägen 144b
112 13 Stockholm
Sweden`
      }
    }
  })*/
}

export const resetTransaction = () =>
  transactionDetailStoreApi.setState({ viewState: 'fetching' })
