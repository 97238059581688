import { Grid } from '@material-ui/core'
import { ChartSelection } from '@root/components/chart-builder/components/chart-selection'
import React from 'react'
import { setQuery } from '../../../store/actions'
import { useChartBuilderStore } from '../../../store/store'

export const Regular: React.FC<{}> = () => {
  const [queries, queryId] = useChartBuilderStore((state) => [
    state.queries,
    state.queryId
  ])

  return (
    <Grid container spacing={4}>
      {queries.map((query, index) => (
        <Grid key={index} item xs={3}>
          <ChartSelection
            variant="query"
            image={query.image}
            title={query.name}
            selected={queryId === query.id}
            click={() => setQuery(query.id)}
          />
        </Grid>
      ))}
    </Grid>
  )
}
