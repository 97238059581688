import { Grid, Typography } from '@material-ui/core'
import React from 'react'

export const Accounts: React.FC<{}> = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="h3">Provide user email accounts</Typography>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          Provide a list of email accounts that should have access to the
          organization in the app. The users will receive an email with a
          password to login into the app.
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)
