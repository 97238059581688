import { Grid } from '@material-ui/core'
import { ActionButton } from '@root/components/action-button'
import { Loader } from '@root/components/loader'
import React, { useEffect } from 'react'
import { AddToDashboardDialog } from './components/add-to-dashboard-dialog'
import { Chart } from './components/chart'
import { DeleteChartDialog } from './components/delete-chart-dialog'
import { RenameChartDialog } from './components/rename-chart-dialog'
import {
  duplicateChart,
  editChart,
  fetchLibrary,
  openChartBuilder,
  openDialog,
  realtimeEditChart
} from './store/actions'
import { useLibraryStore } from './store/store'

export const Charts = () => {
  const [viewState, charts] = useLibraryStore((state) => [
    state.viewState,
    state.charts
  ])

  useEffect(() => {
    fetchLibrary()
  }, [])

  return (
    <>
      {viewState === 'fetching' && <Loader />}
      {viewState === 'library' && (
        <Grid container spacing={3}>
          {charts.map((chart) => (
            <Chart
              key={chart.id}
              chart={chart}
              edit={() => editChart(chart.id)}
              realtimeEdit={() => realtimeEditChart(chart.id)}
              duplicate={() => duplicateChart(chart)}
              addToDashboard={() => openDialog('add-to-dashboard', chart.id)}
              rename={() => openDialog('rename-chart', chart.id)}
              remove={() => openDialog('delete-chart', chart.id)}
            />
          ))}
        </Grid>
      )}
      <ActionButton click={openChartBuilder} />
      <RenameChartDialog />
      <DeleteChartDialog />
      <AddToDashboardDialog />
    </>
  )
}
