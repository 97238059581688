import { Box, Paper as MuiPaper, PaperProps } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import React from 'react'

interface Props extends PaperProps {
  rounded?: boolean
}

export const Paper: React.FC<Props> = ({ rounded, children, ...props }) => (
  <Box
    component={MuiPaper}
    {...props}
    bgcolor={theme.palette.white()}
    boxShadow="0 1px 2px 0 rgba(0, 27, 66, 0.24)"
    borderRadius={rounded ? 14 : 6}
  >
    {children}
  </Box>
)
