import { Column } from '@root/components/data-table/column'
import { SortColumn } from '@shared/models/transactions/sort-column'
import React from 'react'
import { sort } from '../store/actions'
import { useTransactionStore } from '../store/store'

interface Props {
  label: string
  column: SortColumn
}

export const TransactionColumn: React.FC<Props> = ({ label, column }) => {
  const [sortColumn, sortDirection] = useTransactionStore((state) => [
    state.sortColumn,
    state.sortDirection
  ])

  return (
    <Column
      label={label}
      column={column}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      sort={sort}
    />
  )
}
