import { Grid } from '@material-ui/core'
import { DataChartModel } from '@shared/models/charts/any-chart'
import { DashboardModel } from '@shared/models/dashboard'
import React, { useEffect } from 'react'
import { Loader } from '../loader'
import { ChartFinalized } from './components/chart-finalized'
import { Filters } from './components/filters'
import { NameDialog } from './components/name-dialog'
import { Overview } from './components/overview'
import {
  fetchFilters,
  reset,
  setChart,
  setNaming,
  setSaving
} from './store/actions'
import { useChart } from './store/selectors'
import { useRealtimeEditorStore } from './store/store'

interface Props {
  type: 'dashboard' | 'library'
  chart: DataChartModel | undefined
  dashboards: Array<DashboardModel>
  initialDashboardId?: number
  goBack: () => void
  save: (chart: DataChartModel) => void
}

export const RealtimeEditor: React.FC<Props> = ({
  type,
  chart,
  goBack,
  save
}) => {
  const [viewState, name] = useRealtimeEditorStore((state) => [
    state.viewState,
    state.name
  ])
  const updatedChart = useChart()

  useEffect(() => {
    fetchFilters()

    return reset
  }, [])

  useEffect(() => {
    setChart(chart)
  }, [chart])

  const dispatchSave = (updatedName: string) => {
    setSaving()
    save({ ...updatedChart, name: updatedName })
  }

  return (
    <>
      {viewState === 'fetching' && <Loader />}
      {viewState !== 'fetching' && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Overview name={name} save={setNaming} goBack={goBack} />
          </Grid>
          <Grid item xs={12}>
            <ChartFinalized />
          </Grid>
          <Grid item xs={12}>
            <Filters />
          </Grid>
          <NameDialog save={dispatchSave} />
        </Grid>
      )}
    </>
  )
}
