import { setViewing } from '@root/components/chart-builder/store/actions'
import { endpoints } from '@root/transport/http/endpoints'
import {
  DashboardDataChartModel,
  DataChartModel
} from '@shared/models/charts/any-chart'
import { ReportModel } from '@shared/models/report/report'
import { getLinkCharts } from '@shared/helpers/link-charts'
import { ReportScheduleModel } from '@shared/models/report/schedule'
import { ActiveDialog } from './models/active-dialog'
import { dashboardStoreApi, initialState } from './store'

const fetchChartData = (charts: Array<DashboardDataChartModel>) => {
  charts.forEach((chart) => {
    endpoints.charts.get.data.dispatch(chart).then((data) =>
      dashboardStoreApi.setState({
        charts: dashboardStoreApi
          .getState()
          .charts.map((_chart) =>
            _chart.id === chart.id ? { ..._chart, data } : _chart
          )
      })
    )
  })
}

export const reset = () => dashboardStoreApi.setState({ ...initialState })

export const fetchDashboards = async (id: number) => {
  if (dashboardStoreApi.getState().viewState === 'viewing') {
    dashboardStoreApi.setState({ viewState: 'fetching-dashboard' })

    const charts = await endpoints.charts.get.dashboard.dispatch(id)

    fetchChartData(charts)
    dashboardStoreApi.setState({ charts })
  } else {
    const {
      dashboards,
      charts,
      reports
    } = await endpoints.dashboards.getAll.dispatch(id)

    fetchChartData(charts)
    dashboardStoreApi.setState({
      viewState: 'viewing',
      dashboards,
      charts,
      reports,
      selectedDashboardId: dashboards.length ? dashboards[0].id : undefined
    })
  }
}

export const openDashboard = async (id: number) => {
  dashboardStoreApi.setState({
    viewState: 'fetching-dashboard',
    selectedDashboardId: id
  })

  const { charts, reports } = await endpoints.dashboards.get.dispatch(id)

  fetchChartData(charts)
  dashboardStoreApi.setState({
    viewState: 'viewing',
    charts,
    reports
  })
}

export const toggleEditing = () => {
  const { viewState } = dashboardStoreApi.getState()

  if (
    viewState === 'viewing' ||
    viewState === 'edit-order' ||
    viewState === 'edit-reports'
  ) {
    dashboardStoreApi.setState({
      viewState: viewState === 'viewing' ? 'edit-order' : 'viewing'
    })
  }
}

export const toggleEditType = () =>
  dashboardStoreApi.setState({
    viewState:
      dashboardStoreApi.getState().viewState === 'edit-order'
        ? 'edit-reports'
        : 'edit-order'
  })

export const openChartBuilder = () =>
  dashboardStoreApi.setState({
    viewState: 'chart-builder'
  })

export const openRealtimeEdit = (chartId: number) =>
  dashboardStoreApi.setState({
    viewState: 'realtime-edit',
    selectedDialogEntityId: chartId
  })

export const resetViewState = () =>
  dashboardStoreApi.setState({
    viewState: 'viewing'
  })

export const openDialog = (
  activeDialog: ActiveDialog,
  selectedDialogEntityId?: number
) => dashboardStoreApi.setState({ activeDialog, selectedDialogEntityId })

export const closeDialog = () =>
  dashboardStoreApi.setState({ activeDialog: 'none' })

export const deleteDashboard = async () => {
  const { selectedDashboardId } = dashboardStoreApi.getState()

  if (selectedDashboardId) {
    await endpoints.dashboards.remove.dispatch(selectedDashboardId)

    const updatedDashboards = dashboardStoreApi
      .getState()
      .dashboards.filter((dashboard) => dashboard.id !== selectedDashboardId)

    dashboardStoreApi.setState({
      activeDialog: 'none',
      dashboards: updatedDashboards,
      selectedDashboardId: updatedDashboards[0].id
    })

    openDashboard(updatedDashboards[0].id)
  }
}

export const renameDashboard = async (label: string) => {
  const { selectedDashboardId } = dashboardStoreApi.getState()

  if (selectedDashboardId) {
    await endpoints.dashboards.rename.dispatch({
      id: selectedDashboardId,
      name: label
    })

    const updatedDashboards = dashboardStoreApi
      .getState()
      .dashboards.map((dashboard) =>
        dashboard.id === selectedDashboardId
          ? { ...dashboard, label }
          : dashboard
      )

    dashboardStoreApi.setState({
      activeDialog: 'none',
      dashboards: updatedDashboards,
      selectedDashboardId: updatedDashboards[0].id
    })
  }
}

export const saveChart = async (
  chart: DataChartModel,
  saveNew = false,
  assignedDashboardId?: number
) => {
  try {
    const {
      selectedDashboardId,
      selectedDialogEntityId
    } = dashboardStoreApi.getState()
    const existingChart =
      !saveNew &&
      dashboardStoreApi
        .getState()
        .charts.find((_chart) => _chart.id === selectedDialogEntityId)

    const dashboardId = assignedDashboardId || selectedDashboardId || 0
    let newChart: DashboardDataChartModel

    if (existingChart) {
      newChart = await endpoints.charts.save.dashboard.dispatch({
        ...chart,
        id: selectedDialogEntityId,
        dashboardId,
        name: existingChart.name
      })
    } else {
      newChart = await endpoints.charts.save.dashboard.dispatch({
        ...chart,
        id: 0,
        dashboardId
      })
    }

    const { charts } = dashboardStoreApi.getState()

    dashboardStoreApi.setState({
      viewState: 'viewing',
      charts: existingChart
        ? charts.map((_chart) =>
            _chart.id === selectedDialogEntityId
              ? { ...newChart, id: selectedDialogEntityId }
              : _chart
          )
        : [...charts, newChart],
      selectedDialogEntityId: undefined
    })

    if (dashboardId !== selectedDashboardId) {
      openDashboard(dashboardId)
    }

    fetchChartData([newChart])
  } catch (e) {
    // TODO: Should be handled in chart buildeR?
    setViewing()
    throw e
  }
}

export const editChart = (selectedDialogEntityId: number) =>
  dashboardStoreApi.setState({
    viewState: 'chart-builder',
    selectedDialogEntityId
  })

export const duplicateChart = (chart: DashboardDataChartModel) =>
  saveChart({ ...chart, id: 0, name: `${chart.name} (copy)` })

export const reorderChart = (from: number, to: number) => {
  if (from !== to) {
    const { id } = dashboardStoreApi.getState().charts[from]
    const charts = dashboardStoreApi
      .getState()
      .charts.map((chart, index, array) =>
        chart.id === id || array[index + 1]?.id === id
          ? { ...chart, linkedWithNext: false }
          : chart
      )
    const [removed] = charts.splice(from, 1)
    charts.splice(to, 0, removed)

    dashboardStoreApi.setState({ charts })
    endpoints.charts.manage.reorder.dispatch({
      dashboardId: dashboardStoreApi.getState().selectedDashboardId || 0,
      id,
      order: to + 1
    })
  }
}

export const toggleChartLink = async (
  selectedChart: DashboardDataChartModel
) => {
  if (selectedChart.linkedWithNext) {
    dashboardStoreApi.setState({
      charts: dashboardStoreApi
        .getState()
        .charts.map((chart) =>
          chart.id === selectedChart.id
            ? { ...selectedChart, linkedWithNext: false }
            : chart
        )
    })
    await endpoints.charts.manage.unlink.dispatch({
      dashboardId: dashboardStoreApi.getState().selectedDashboardId || 0,
      id: selectedChart.id
    })
  } else {
    dashboardStoreApi.setState({
      charts: getLinkCharts(dashboardStoreApi.getState().charts, selectedChart)
    })

    await endpoints.charts.manage.link.dispatch({
      dashboardId: dashboardStoreApi.getState().selectedDashboardId || 0,
      id: selectedChart.id
    })
  }
}

export const renameChart = async (name: string) => {
  const { charts, selectedDialogEntityId } = dashboardStoreApi.getState()

  if (selectedDialogEntityId) {
    await endpoints.charts.manage.rename.dispatch({
      id: selectedDialogEntityId,
      name
    })

    dashboardStoreApi.setState({
      activeDialog: 'none',
      charts: charts.map((chart) =>
        chart.id === selectedDialogEntityId ? { ...chart, name } : chart
      )
    })
  }
}

export const removeChart = async () => {
  const {
    selectedDashboardId,
    selectedDialogEntityId
  } = dashboardStoreApi.getState()

  if (selectedDashboardId && selectedDialogEntityId) {
    await endpoints.charts.manage.removeFromDashboard.dispatch({
      dashboardId: selectedDashboardId,
      id: selectedDialogEntityId
    })

    dashboardStoreApi.setState({
      viewState: 'viewing',
      charts: dashboardStoreApi
        .getState()
        .charts.filter((chart) => chart.id !== selectedDialogEntityId),
      activeDialog: 'none'
    })
  }
}

export const saveReport = async (
  id: number | undefined,
  name: string,
  schedules: Array<ReportScheduleModel>,
  recipients: Array<string>
) => {
  const { selectedDialogEntityId, reports } = dashboardStoreApi.getState()

  if (selectedDialogEntityId) {
    const report = await endpoints.charts.reports.save.dispatch({
      id,
      chartId: id
        ? (reports.find((_report) => _report.id === id) as ReportModel).chartId
        : selectedDialogEntityId,
      name,
      schedules,
      recipients
    })

    dashboardStoreApi.setState({
      reports: id
        ? reports.map((_report) =>
            _report.id === selectedDialogEntityId ? report : _report
          )
        : [...reports, report],
      activeDialog: 'none'
    })
  }
}

export const removeReport = async () => {
  const { selectedDialogEntityId } = dashboardStoreApi.getState()

  if (selectedDialogEntityId) {
    await endpoints.charts.reports.remove.dispatch(selectedDialogEntityId)

    dashboardStoreApi.setState({
      reports: dashboardStoreApi
        .getState()
        .reports.filter((report) => report.id !== selectedDialogEntityId),
      activeDialog: 'none'
    })
  }
}
