import { dashboardIcons } from '@root/constants/dashboard-icons'
import { useSaveDialogStore } from './store'

const typesPerPage = 8

export const useDashboardIcons = () => {
  const typePage = useSaveDialogStore((state) => state.typePage)

  return dashboardIcons.slice(
    (typePage - 1) * typesPerPage,
    typePage * typesPerPage
  )
}

export const useMayPreviousTypePage = (): boolean => {
  const typePage = useSaveDialogStore((state) => state.typePage)

  return typePage > 1
}

export const useMayNextTypePage = (): boolean => {
  const typePage = useSaveDialogStore((state) => state.typePage)

  return typePage < Math.ceil(dashboardIcons.length / typesPerPage)
}
