import { Grid } from '@material-ui/core'
import { Loader } from '@root/components/loader'
import React, { useEffect } from 'react'
import { Filter } from './components/filter/filter'
import { TransactionTable } from './components/transaction-table'
import { fetchTransactions } from './store/actions'
import { useTransactionStore } from './store/store'

export const TransactionList = () => {
  const viewState = useTransactionStore((state) => state.viewState)

  useEffect(() => {
    fetchTransactions()
  }, [])

  return (
    <>
      {viewState === 'fetching' && <Loader />}
      {viewState !== 'fetching' && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Filter />
          </Grid>
          <Grid item xs={12}>
            <TransactionTable />
          </Grid>
        </Grid>
      )}
    </>
  )
}
