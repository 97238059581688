import { Column } from '@root/components/data-table/column'
import React from 'react'
import { SortColumn } from '../models/sort-column'
import { sort } from '../store/actions'
import { useUserStore } from '../store/store'

interface Props {
  label: string
  column: SortColumn
  width: string
}

export const UserColumn: React.FC<Props> = ({ label, column, width }) => {
  const [sortColumn, sortDirection] = useUserStore((state) => [
    state.sortColumn,
    state.sortDirection
  ])

  return (
    <Column
      label={label}
      column={column}
      width={width}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      sort={sort}
    />
  )
}
