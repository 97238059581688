import { ReactComponent as AirFreshenerIcon } from '@root/assets/images/dashboard-icons/air-freshener.svg'
import { ReactComponent as AlarmClockIcon } from '@root/assets/images/dashboard-icons/alarm-clock.svg'
import { ReactComponent as ApertureIcon } from '@root/assets/images/dashboard-icons/aperture.svg'
import { ReactComponent as AppleWholeIcon } from '@root/assets/images/dashboard-icons/apple-whole.svg'
import { ReactComponent as ArrowsRotateIcon } from '@root/assets/images/dashboard-icons/arrows-rotate.svg'
import { ReactComponent as BabyCarriageIcon } from '@root/assets/images/dashboard-icons/baby-carriage.svg'
import { ReactComponent as BadgeCheckIcon } from '@root/assets/images/dashboard-icons/badge-check.svg'
import { ReactComponent as BluetoothIcon } from '@root/assets/images/dashboard-icons/bluetooth.svg'
import { ReactComponent as BoltIcon } from '@root/assets/images/dashboard-icons/bolt.svg'
import { ReactComponent as BombIcon } from '@root/assets/images/dashboard-icons/bomb.svg'
import { ReactComponent as BowArrowIcon } from '@root/assets/images/dashboard-icons/bow-arrow.svg'
import { ReactComponent as BowlingBallIcon } from '@root/assets/images/dashboard-icons/bowling-ball.svg'
import { ReactComponent as BullseyeArrowIcon } from '@root/assets/images/dashboard-icons/bullseye-arrow.svg'
import { ReactComponent as BurgerCheeseIcon } from '@root/assets/images/dashboard-icons/burger-cheese.svg'
import { ReactComponent as BusSchoolIcon } from '@root/assets/images/dashboard-icons/bus-school.svg'
import { ReactComponent as CalendarHeartIcon } from '@root/assets/images/dashboard-icons/calendar-heart.svg'
import { ReactComponent as CalendarStarIcon } from '@root/assets/images/dashboard-icons/calendar-star.svg'
import { ReactComponent as ChartPieSimpleIcon } from '@root/assets/images/dashboard-icons/chart-pie-simple.svg'
import { ReactComponent as CloudMusicIcon } from '@root/assets/images/dashboard-icons/cloud-music.svg'
import { ReactComponent as CometIcon } from '@root/assets/images/dashboard-icons/comet.svg'
import { ReactComponent as CommentDollarIcon } from '@root/assets/images/dashboard-icons/comment-dollar.svg'
import { ReactComponent as DoveIcon } from '@root/assets/images/dashboard-icons/dove.svg'
import { ReactComponent as EarthAfricaIcon } from '@root/assets/images/dashboard-icons/earth-africa.svg'
import { ReactComponent as FeatherPointedIcon } from '@root/assets/images/dashboard-icons/feather-pointed.svg'
import { ReactComponent as FireFlameCurvedIcon } from '@root/assets/images/dashboard-icons/fire-flame-curved.svg'
import { ReactComponent as FlagCheckeredIcon } from '@root/assets/images/dashboard-icons/flag-checkered.svg'
import { ReactComponent as FlowerTulipIcon } from '@root/assets/images/dashboard-icons/flower-tulip.svg'
import { ReactComponent as FootballHelmetIcon } from '@root/assets/images/dashboard-icons/football-helmet.svg'
import { ReactComponent as GamepadModernIcon } from '@root/assets/images/dashboard-icons/gamepad-modern.svg'
import { ReactComponent as GuitarIcon } from '@root/assets/images/dashboard-icons/guitar.svg'
import { ReactComponent as HashtagIcon } from '@root/assets/images/dashboard-icons/hashtag.svg'
import { ReactComponent as LandmarkIcon } from '@root/assets/images/dashboard-icons/landmark.svg'
import { ReactComponent as MartiniGlassEmptyIcon } from '@root/assets/images/dashboard-icons/martini-glass-empty.svg'
import { ReactComponent as MedalIcon } from '@root/assets/images/dashboard-icons/medal.svg'
import { ReactComponent as PlugIcon } from '@root/assets/images/dashboard-icons/plug.svg'
import { ReactComponent as ShieldExclamationIcon } from '@root/assets/images/dashboard-icons/shield-exclamation.svg'
import { ReactComponent as StarSharpIcon } from '@root/assets/images/dashboard-icons/star-sharp.svg'
import { ReactComponent as SunBrightIcon } from '@root/assets/images/dashboard-icons/sun-bright.svg'
import { ReactComponent as SunglassesIcon } from '@root/assets/images/dashboard-icons/sunglasses.svg'
import { ReactComponent as TreeDecoratedIcon } from '@root/assets/images/dashboard-icons/tree-decorated.svg'
import { ReactComponent as TrophyStarIcon } from '@root/assets/images/dashboard-icons/trophy-star.svg'
import { ReactComponent as UserGraduateIcon } from '@root/assets/images/dashboard-icons/user-graduate.svg'
import { ReactComponent as WhaleIcon } from '@root/assets/images/dashboard-icons/whale.svg'
import { ReactComponent as WineGlassCrackIcon } from '@root/assets/images/dashboard-icons/wine-glass-crack.svg'
import { DashboardType } from '@shared/models/dashboard'

export const dashboardIcons: Array<{
  type: DashboardType
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}> = [
  { type: 'air-freshener', icon: AirFreshenerIcon },
  { type: 'alarm-clock', icon: AlarmClockIcon },
  { type: 'aperture', icon: ApertureIcon },
  { type: 'apple-whole', icon: AppleWholeIcon },
  { type: 'arrows-rotate', icon: ArrowsRotateIcon },
  { type: 'baby-carriage', icon: BabyCarriageIcon },
  { type: 'badge-check', icon: BadgeCheckIcon },
  { type: 'bluetooth', icon: BluetoothIcon },
  { type: 'bolt', icon: BoltIcon },
  { type: 'bomb', icon: BombIcon },
  { type: 'bow-arrow', icon: BowArrowIcon },
  { type: 'bowling-ball', icon: BowlingBallIcon },
  { type: 'bullseye-arrow', icon: BullseyeArrowIcon },
  { type: 'burger-cheese', icon: BurgerCheeseIcon },
  { type: 'bus-school', icon: BusSchoolIcon },
  { type: 'calendar-heart', icon: CalendarHeartIcon },
  { type: 'calendar-star', icon: CalendarStarIcon },
  { type: 'chart-pie-simple', icon: ChartPieSimpleIcon },
  { type: 'cloud-music', icon: CloudMusicIcon },
  { type: 'comet', icon: CometIcon },
  { type: 'comment-dollar', icon: CommentDollarIcon },
  { type: 'dove', icon: DoveIcon },
  { type: 'earth-africa', icon: EarthAfricaIcon },
  { type: 'feather-pointed', icon: FeatherPointedIcon },
  { type: 'fire-flame-curved', icon: FireFlameCurvedIcon },
  { type: 'flag-checkered', icon: FlagCheckeredIcon },
  { type: 'flower-tulip', icon: FlowerTulipIcon },
  { type: 'football-helmet', icon: FootballHelmetIcon },
  { type: 'gamepad-modern', icon: GamepadModernIcon },
  { type: 'guitar', icon: GuitarIcon },
  { type: 'hashtag', icon: HashtagIcon },
  { type: 'landmark', icon: LandmarkIcon },
  { type: 'martini-glass-empty', icon: MartiniGlassEmptyIcon },
  { type: 'medal', icon: MedalIcon },
  { type: 'plug', icon: PlugIcon },
  { type: 'shield-exclamation', icon: ShieldExclamationIcon },
  { type: 'star-sharp', icon: StarSharpIcon },
  { type: 'sun-bright', icon: SunBrightIcon },
  { type: 'sunglasses', icon: SunglassesIcon },
  { type: 'tree-decorated', icon: TreeDecoratedIcon },
  { type: 'trophy-star', icon: TrophyStarIcon },
  { type: 'user-graduate', icon: UserGraduateIcon },
  { type: 'whale', icon: WhaleIcon },
  { type: 'wine-glass-crack', icon: WineGlassCrackIcon }
]
