import TextField from '@material-ui/core/TextField'
import { setPassword } from '@root/store/auth/actions'
import { useAuthStore } from '@root/store/auth/store'
import React from 'react'

export const PasswordField: React.FC = () => {
  const [authState, password] = useAuthStore((state) => [
    state.authState,
    state.password
  ])

  return (
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      label="Password"
      type="password"
      autoComplete="current-password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      disabled={authState === 'authenticating'}
    />
  )
}
