// TODO: More advanced formatter?
export const tickFormatter = (value: number): string => {
  if (value >= 1000000) {
    return `${value / 1000000}m`
  } else if (value >= 1000) {
    return `${value / 1000}${value ? 'k' : ''}`
  }

  return value.toString()
}
