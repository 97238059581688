import { endpoints } from '@root/transport/http/endpoints'
import { getDateString } from '@shared/constants/date-string'
import { DataChartModel } from '@shared/models/charts/any-chart'
import { ChartMethod } from '@shared/models/charts/chart-method'
import { FilterComparator } from '@shared/models/charts/filter-comparator'
import { ChartFromDateType } from '@shared/models/charts/from-date-type'
import { endOfMonth, startOfMonth } from 'date-fns'
import { Step } from '../models/step'
import { ChartBuilderState, chartBuilderStoreApi, initialState } from './store'

const getNextStep = (
  method: ChartMethod,
  currentStep: Step,
  customQuery: string
): Step => {
  switch (currentStep) {
    case 'what-to-see':
      return method === 'custom' ? 'select-chart' : 'filters'
    case 'filters':
      return method === 'advanced' ? 'name-chart' : 'select-chart'
    case 'select-chart':
      // TODO: The result of try/catching ChartRenderer
      return customQuery === initialState.customQuery
        ? 'name-chart'
        : 'chart-finalized'
    case 'name-chart':
    default:
      return 'chart-finalized'
  }
}

const getChart = (): DataChartModel => {
  const {
    id,
    name,
    method,
    variant,
    queryId,
    advancedQueryId,
    typeId,
    customQuery,
    fromDateType,
    fromDateValue,
    toDate,
    top,
    filters,
    data,
    types
  } = chartBuilderStoreApi.getState()

  const advanced = method === 'advanced'

  return {
    id: id || 0,
    name,
    method,
    variant: advanced ? 'single-value' : variant,
    advancedQueryId,
    queryId,
    typeId: advanced
      ? types.find((type) => type.variant === 'single-value')?.id || 0
      : typeId,
    fromDateType,
    fromDateValue,
    toDate,
    top,
    customQuery: method === 'custom' ? customQuery : '',
    filters: filters.filter((filter) => filter.column && filter.value),
    data
  }
}

export const reset = () => chartBuilderStoreApi.setState({ ...initialState })

export const fetchChartBuilderData = async () => {
  const {
    drafts,
    filterColumns,
    types,
    queries,
    advancedQueries
  } = await endpoints.charts.getChartBuilderData.dispatch()
  const { queryId, advancedQueryId, typeId } = chartBuilderStoreApi.getState()
  chartBuilderStoreApi.setState({
    viewState: 'viewing',
    drafts,
    filterColumns,
    types,
    queries,
    advancedQueries,
    queryId: queryId || queries[0].id,
    advancedQueryId: advancedQueryId || advancedQueries[0].id,
    typeId: typeId || types[0].id
  })
}

export const setChart = (chart: DataChartModel | undefined) => {
  if (chart) {
    chartBuilderStoreApi.setState({ ...chart })
  }
}

export const activateDraft = (id: number) => {
  const { drafts } = chartBuilderStoreApi.getState()
  const draft = drafts.find((_draft) => _draft.id === id)

  if (draft) {
    const { method, queryId, customQuery, typeId, filters } = draft

    chartBuilderStoreApi.setState({
      drafts,
      method,
      queryId,
      customQuery,
      typeId,
      filters
    })
  }
}

export const removeDraft = async (id: number) => {
  await endpoints.charts.manage.remove.dispatch(id)
  chartBuilderStoreApi.setState({
    drafts: chartBuilderStoreApi
      .getState()
      .drafts.filter((draft) => draft.id !== id)
  })
}

export const addDraft = async (name: string) => {
  chartBuilderStoreApi.setState({
    drafts: [
      ...chartBuilderStoreApi.getState().drafts,
      await endpoints.charts.drafts.save.dispatch({ ...getChart(), name })
    ]
  })
}

export const createCustom = () =>
  chartBuilderStoreApi.setState({ method: 'custom' })

export const createAdvanced = () =>
  chartBuilderStoreApi.setState({ method: 'advanced' })

export const gotoStep = (step: Step) =>
  chartBuilderStoreApi.setState({
    currentStep:
      chartBuilderStoreApi.getState().method === 'custom' && step === 'filters'
        ? 'what-to-see'
        : step,
    chartRenderError: false,
    data: undefined
  })

export const traverseStep = async (
  save: (chart: DataChartModel) => void,
  editing: boolean
) => {
  const {
    types,
    queries,
    method,
    currentStep,
    queryId,
    typeId,
    customQuery
  } = chartBuilderStoreApi.getState()
  const nextStep = getNextStep(method, currentStep, customQuery)
  const stateChange: Partial<ChartBuilderState> = {
    currentStep: nextStep
  }
  if (nextStep === 'select-chart') {
    const query = queries.find((_query) => _query.id === queryId)!

    if (!query.availableTypes.includes(typeId)) {
      stateChange.typeId = types.filter(
        (type) => method === 'custom' || query.availableTypes.includes(type.id)
      )[0].id
    }
  }

  if (
    nextStep === 'name-chart' ||
    (nextStep === 'chart-finalized' && customQuery !== initialState.customQuery)
  ) {
    if (editing) {
      chartBuilderStoreApi.setState({ data: undefined })
      save(getChart())
    } else {
      endpoints.charts.get.data
        .dispatch({
          ...getChart(),
          name: 'test'
        })
        .then((data) =>
          chartBuilderStoreApi.setState({
            data
          })
        )
    }
  }

  chartBuilderStoreApi.setState(stateChange)
}

export const closeDialog = () =>
  chartBuilderStoreApi.setState({
    currentStep:
      chartBuilderStoreApi.getState().method === 'regular'
        ? 'chart-finalized'
        : 'select-chart'
  })

export const nameChart = async (
  name: string,
  save: (chart: DataChartModel) => void
) => {
  const {
    id,
    method,
    currentStep,
    customQuery
  } = chartBuilderStoreApi.getState()

  if (id) {
    save({ ...getChart(), name })
  } else {
    chartBuilderStoreApi.setState({
      name,
      currentStep: getNextStep(method, currentStep, customQuery)
    })
  }
}

export const setQuery = (queryId: number) =>
  chartBuilderStoreApi.setState({ queryId })

export const setAdvancedQuery = (advancedQueryId: number) =>
  chartBuilderStoreApi.setState({ advancedQueryId })

export const setCustomQuery = (customQuery: string) =>
  chartBuilderStoreApi.setState({ customQuery })

export const setChartType = (typeId: number) =>
  chartBuilderStoreApi.setState({ typeId })

export const setFromDateType = (fromDateType: ChartFromDateType) =>
  chartBuilderStoreApi.setState({
    fromDateType,
    fromDateValue:
      fromDateType === 'exact-date'
        ? getDateString(startOfMonth(new Date()))
        : '30',
    toDate:
      fromDateType === 'exact-date' ? getDateString(endOfMonth(new Date())) : ''
  })

export const setFromDateValue = (fromDateValue: string) =>
  chartBuilderStoreApi.setState({ fromDateValue })

export const setToDate = (toDate: string) =>
  chartBuilderStoreApi.setState({ toDate })

export const setTop = (top: number) => chartBuilderStoreApi.setState({ top })

export const addFilter = () =>
  chartBuilderStoreApi.setState({
    filters: [
      ...chartBuilderStoreApi.getState().filters,
      { comparator: 'equals', column: '', value: '' }
    ]
  })

export const setFilterColumn = (filterIndex: number, column: string) =>
  chartBuilderStoreApi.setState({
    filters: chartBuilderStoreApi
      .getState()
      .filters.map((filter, index) =>
        index === filterIndex ? { ...filter, column } : filter
      )
  })

export const setFilterComparator = (
  filterIndex: number,
  comparator: FilterComparator
) =>
  chartBuilderStoreApi.setState({
    filters: chartBuilderStoreApi
      .getState()
      .filters.map((filter, index) =>
        index === filterIndex ? { ...filter, comparator } : filter
      )
  })

export const setFilterValue = (filterIndex: number, value: string) =>
  chartBuilderStoreApi.setState({
    filters: chartBuilderStoreApi
      .getState()
      .filters.map((filter, index) =>
        index === filterIndex ? { ...filter, value } : filter
      )
  })

export const removeFilter = (filterIndex: number) =>
  chartBuilderStoreApi.setState({
    filters: chartBuilderStoreApi
      .getState()
      .filters.filter((filter, index) => index !== filterIndex)
  })

export const setRenderError = () =>
  chartBuilderStoreApi.setState({
    currentStep: 'chart-finalized',
    chartRenderError: true
  })

export const setViewing = () =>
  chartBuilderStoreApi.setState({ viewState: 'viewing' })

export const setLoading = () =>
  chartBuilderStoreApi.setState({ viewState: 'saving' })
