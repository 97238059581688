import { Box } from '@material-ui/core'
import { theme } from '@root/constants/theme'
import React from 'react'

interface Props {
  label: string
  value: string
}

export const FieldBlock: React.FC<Props> = ({ label, value }) => (
  <Box py={1}>
    <Box
      component="span"
      display="block"
      color={theme.palette.dark('light')}
      fontSize={10}
    >
      {label}
    </Box>
    <Box component="span" fontSize={16} whiteSpace="pre">
      {value}
    </Box>
  </Box>
)
