import { Box } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { IconButton } from '@root/components/icon-button'
import { Paper } from '@root/components/paper'
import { theme } from '@root/constants/theme'
import React from 'react'

export const OperationsHistory = () => (
  <Box
    component={Paper}
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    height={55}
    px={2}
  >
    <Box
      color={theme.palette.dark()}
      fontSize={13}
      fontWeight={900}
      fontStyle="italic"
      letterSpacing={0.81}
    >
      OPERATIONS HISTORY
    </Box>
    <IconButton secondary mediumSmall icon={<KeyboardArrowDownIcon />} />
  </Box>
)
