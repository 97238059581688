import { TextField } from '@material-ui/core'
import { Button } from '@root/components/button'
import { Dialog } from '@root/components/dialog/dialog'
import { OutlineButton } from '@root/components/outline-button'
import {
  closeDialog,
  renameDashboard
} from '@root/pages/dashboard/store/actions'
import { useSelectedDashboard } from '@root/pages/dashboard/store/selectors'
import { useDashboardStore } from '@root/pages/dashboard/store/store'
import React, { useEffect, useState } from 'react'

export const RenameDialog: React.FC<{}> = () => {
  const activeDialog = useDashboardStore((state) => state.activeDialog)
  const selectedDashboard = useSelectedDashboard()
  const [name, setName] = useState('')

  useEffect(() => {
    setName(selectedDashboard.label)
  }, [selectedDashboard])

  return (
    <Dialog
      open={activeDialog === 'rename-dashboard'}
      title="Rename dashboard"
      content={
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      }
      actions={
        <>
          <OutlineButton secondary onClick={closeDialog}>
            Cancel
          </OutlineButton>
          <Button primary onClick={() => renameDashboard(name)}>
            Save changes
          </Button>
        </>
      }
      onClose={closeDialog}
    />
  )
}
