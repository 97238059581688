import { MenuItem, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { saveNewSavedSearch } from '../../../store/actions'
import { useTransactionStore } from '../../../store/store'

export const SavedSearchNameField = () => {
  const [name, setName] = useState('')
  const newSavedSearchFilters = useTransactionStore(
    (state) => state.newSavedSearchFilters
  )

  const save = (e: { preventDefault: () => void }) => {
    saveNewSavedSearch(name)
    setName('')
    e.preventDefault()
  }

  return newSavedSearchFilters.length ? (
    <MenuItem>
      <form onSubmit={save}>
        <TextField
          autoFocus
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={save}
        />
      </form>
    </MenuItem>
  ) : null
}
