import { Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { DeleteUserDialog } from './components/delete-dialog'
import { UserDialog } from './components/user-dialog/user-dialog'
import { UserSearch } from './components/user-search'
import { UserTable } from './components/user-table/user-table'
import { fetchUsers, resetUsers } from './store/actions'

export const Users = () => {
  useEffect(() => {
    fetchUsers()
    return resetUsers
  }, [])

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <UserSearch />
        </Grid>
        <Grid item xs={12}>
          <UserTable />
        </Grid>
      </Grid>
      <DeleteUserDialog />
      <UserDialog />
    </>
  )
}
