import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core'
import { Delete, Edit as EditIcon, Timer } from '@material-ui/icons'
import { IconButton } from '@root/components/icon-button'
import React from 'react'
import { openDialog } from '../../../store/actions'
import { useDashboardStore } from '../../../store/store'

export const Reports: React.FC<{}> = () => {
  const reports = useDashboardStore((state) => state.reports)

  return (
    <List disablePadding>
      {reports.map((report, index) => (
        <Box
          key={index}
          display="flex"
          alignItems="center"
          mb={1}
          bgcolor="white"
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Timer />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={report.name} secondary={report.chartName} />
          </ListItem>
          <Box px={2}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item>
                <IconButton
                  icon={<EditIcon />}
                  onClick={() => openDialog('edit-report', report.id)}
                />
              </Grid>
              <Grid item>
                <IconButton
                  icon={<Delete />}
                  onClick={() => openDialog('delete-report', report.id)}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      ))}
    </List>
  )
}
